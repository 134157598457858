import React from "react";

export const Plus = ({ color = "#8029FF", className }) => {
  return (
    <svg
      className={`plus ${className}`}
      fill="none"
      height="48"
      viewBox="0 0 48 48"
      width="48"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M40.32 24.3864C40.32 23.181 40.2118 22.0219 40.0109 20.9092H24V27.4851H33.1491C32.755 29.6101 31.5573 31.4105 29.7568 32.616V36.8815H35.2509C38.4654 33.9219 40.32 29.5637 40.32 24.3864Z"
        fill={color}
        fillRule="evenodd"
      />

      <path
        className="path"
        clipRule="evenodd"
        d="M24 41.0001C28.59 41.0001 32.4382 39.4778 35.2509 36.8814L29.7568 32.616C28.2345 33.636 26.2873 34.2387 24 34.2387C19.5722 34.2387 15.8245 31.2482 14.4877 27.23H8.80814V31.6346C11.6054 37.1905 17.3545 41.0001 24 41.0001Z"
        fill="#34A853"
        fillRule="evenodd"
      />

      <path
        className="path"
        clipRule="evenodd"
        d="M14.4877 27.2301C14.1477 26.2101 13.9545 25.1206 13.9545 24.0001C13.9545 22.8797 14.1477 21.7901 14.4877 20.7701V16.3656H8.80814C7.65677 18.6606 6.99995 21.2569 6.99995 24.0001C6.99995 26.7433 7.65677 29.3397 8.80814 31.6347L14.4877 27.2301Z"
        fill="#FBBC05"
        fillRule="evenodd"
      />

      <path
        className="path"
        clipRule="evenodd"
        d="M24 13.7614C26.4959 13.7614 28.7368 14.6192 30.4986 16.3037L35.3745 11.4278C32.4304 8.68461 28.5823 7.00006 24 7.00006C17.3545 7.00006 11.6054 10.8096 8.80814 16.3655L14.4877 20.7701C15.8245 16.7519 19.5722 13.7614 24 13.7614Z"
        fill="#EA4335"
        fillRule="evenodd"
      />
    </svg>
  );
};