import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAPIService from './APIService';
import './styles/Application.css';
import { cacheService } from '../cacheService';

const LoadingOverlay = ({ message }) => (
  <div className="application-loading-overlay">
    <div className="application-spinner" />
    <div className="application-loading-text">{message || 'Loading...'}</div>
  </div>
);

const Application = () => {
  const { positionId } = useParams();
  const api = useAPIService();
  const [loading, setLoading] = useState(true);
  const [position, setPosition] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [submitError, setSubmitError] = useState('');
  
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    resume: null,
    resume_name: '',
    portfolio_url: '',
    linkedin_url: '',
    years_of_experience: '',
    why_pomu: '',
    notes: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  useEffect(() => {
    const fetchPosition = async () => {
      try {
        const cachedPosition = cacheService.getPositionDetails(positionId);
        if (cachedPosition) {
          setPosition(cachedPosition);
          setLoading(false);
          return;
        }

        const response = await api.GetPositionDetails(positionId);
        setPosition(response.position);
        cacheService.setPositionDetails(positionId, response.position);
      } catch (err) {
        console.error('Error fetching position:', err);
        setSubmitError('Unable to load position details. Please try refreshing the page.');
      } finally {
        setLoading(false);
      }
    };

    if (positionId !== 'general') {
      fetchPosition();
    } else {
      setLoading(false);
    }
  }, [api, positionId]);

  const validateForm = () => {
    const errors = {};
    
    if (!formData.first_name.trim()) {
      errors.first_name = 'First name is required';
    }
    
    if (!formData.last_name.trim()) {
      errors.last_name = 'Last name is required';
    }
    
    if (!formData.email.trim()) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }
    
    if (formData.portfolio_url && !/^https?:\/\//.test(formData.portfolio_url)) {
      errors.portfolio_url = 'Please enter a valid URL starting with http:// or https://';
    }
    
    if (formData.linkedin_url && !/^https?:\/\//.test(formData.linkedin_url)) {
      errors.linkedin_url = 'Please enter a valid URL starting with http:// or https://';
    }
    
    if (formData.years_of_experience) {
      const years = parseInt(formData.years_of_experience);
      if (isNaN(years) || years < 0 || years > 50) {
        errors.years_of_experience = 'Please enter a valid number between 0 and 50';
      }
    }
    
    if (!formData.why_pomu.trim()) {
      errors.why_pomu = 'Please tell us why you want to join Pomu';
    }

    if (!formData.resume) {
      errors.resume = 'Please upload your resume';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error for this field when user starts typing
    if (formErrors[name]) {
      setFormErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
    
    // Clear submit error when user makes any changes
    if (submitError) {
      setSubmitError('');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
          .includes(file.type)) {
        setFormErrors(prev => ({
          ...prev,
          resume: 'Please upload a PDF or Word document'
        }));
        return;
      }
      
      if (file.size > 10 * 1024 * 1024) { // 10MB limit
        setFormErrors(prev => ({
          ...prev,
          resume: 'File size must be less than 10MB'
        }));
        return;
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData(prev => ({
          ...prev,
          resume: event.target.result.split(',')[1],
          resume_name: file.name
        }));
        // Clear resume error if present
        setFormErrors(prev => ({
          ...prev,
          resume: ''
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError('');
    
    if (!validateForm()) {
      // Scroll to the first error
      const firstErrorField = Object.keys(formErrors)[0];
      const errorElement = document.getElementById(firstErrorField);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }

    setIsSubmitting(true);

    try {
      const submitData = {
        ...formData,
        position_id: positionId !== 'general' ? positionId : null,
        years_of_experience: formData.years_of_experience || '0'
      };

      const response = await api.SubmitApplication(submitData);
      if (response.status === 'success') {
        cacheService.invalidatePositionsCache();
        setIsSubmitted(true);
        window.scrollTo(0, 0);
      } else {
        throw new Error(response.message || 'Failed to submit application');
      }
    } catch (err) {
      console.error('Error submitting application:', err);
      setSubmitError(
        err.response?.data?.message || 
        'Unable to submit your application at this time. Please try again later.'
      );
      window.scrollTo(0, 0);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderFieldError = (fieldName) => {
    if (formErrors[fieldName]) {
      return <div className="application-error-message">{formErrors[fieldName]}</div>;
    }
    return null;
  };

  if (loading) {
    return (
      <div className="application-container">
        <LoadingOverlay message="Loading position details..." />
      </div>
    );
  }

  if (isSubmitted) {
    return (
      <div className="application-container">
        <div className="application-back-button-container">
          <a href="/careers" className="application-back-button">
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M19 12H5M12 19l-7-7 7-7"/>
            </svg>
            Back to Careers
          </a>
        </div>
        
        <div className="application-header">
          <div className="application-department-tag">Application Submitted</div>
          <h1>Thank You for Applying!</h1>
        </div>
        
        <div className="application-success-message">
          <div className="application-success-icon">
            <svg 
              width="64" 
              height="64" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
              <polyline points="22 4 12 14.01 9 11.01"></polyline>
            </svg>
          </div>
          
          <h2 className="application-success-title">Your Application Has Been Received</h2>
          <div className="application-success-content">
            <p className="application-success-text">
              Thank you for your interest in joining our team! We've received your application 
              and our recruiting team will carefully review your qualifications. If your 
              background matches what we're looking for, we'll reach out to discuss next steps.
            </p>
            <div className="application-social-links">
              <a href="/careers" className="application-social-link">
                View More Opportunities
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="application-container">
      {isSubmitting && <LoadingOverlay message="Submitting your application..." />}
      
      <div className="application-back-button-container">
        <a href="/careers" className="application-back-button">
          <svg 
            width="20" 
            height="20" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M19 12H5M12 19l-7-7 7-7"/>
          </svg>
          Back to Careers
        </a>
      </div>

      {submitError && (
        <div className="application-error-banner">
          {submitError}
        </div>
      )}
      
      <div className="application-header">
        <h1>{position ? position.title : 'General Application'}</h1>
        {position && (
          <div className="application-position-details">
            <span className="application-department-tag">{position.department}</span>
            <div className="application-position-description">
              <h2>About the Role</h2>
              <p>{position.description}</p>
              <h2>Requirements</h2>
              {Array.isArray(position.requirements) ? (
                <ul className="list-disc pl-6 space-y-2">
                  {position.requirements.map((requirement, index) => (
                    <li key={index} className="text-gray-700">
                      {requirement.trim()}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>{position.requirements}</p>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="application-form-container">
        <form onSubmit={handleSubmit} className="application-form">
          <div className="application-form-section">
            <h2>Personal Information</h2>
            <div className={`application-form-group ${formErrors.first_name ? 'error' : ''}`}>
              <label htmlFor="first_name" className="application-required-field">First Name</label>
              <input
                type="text"
                id="first_name"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
              {renderFieldError('first_name')}
            </div>

            <div className={`application-form-group ${formErrors.last_name ? 'error' : ''}`}>
              <label htmlFor="last_name" className="application-required-field">Last Name</label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
              {renderFieldError('last_name')}
            </div>

            <div className={`application-form-group ${formErrors.email ? 'error' : ''}`}>
              <label htmlFor="email" className="application-required-field">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {renderFieldError('email')}
            </div>

            <div className={`application-form-group ${formErrors.phone ? 'error' : ''}`}>
              <label htmlFor="phone">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
              {renderFieldError('phone')}
            </div>
          </div>

          <div className="application-form-section">
            <h2>Professional Information</h2>
            <div className={`application-form-group ${formErrors.resume ? 'error' : ''}`}>
              <label htmlFor="resume" className="application-required-field">Resume/CV</label>
              <input
                type="file"
                id="resume"
                name="resume"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                required
              />
              {formData.resume_name && (
                <div className="application-file-name">Selected file: {formData.resume_name}</div>
              )}
              {renderFieldError('resume')}
            </div>

            <div className={`application-form-group ${formErrors.portfolio_url ? 'error' : ''}`}>
              <label htmlFor="portfolio_url">Portfolio URL</label>
              <input
                type="url"
                id="portfolio_url"
                name="portfolio_url"
                value={formData.portfolio_url}
                onChange={handleInputChange}
              />
              {renderFieldError('portfolio_url')}
            </div>

            <div className={`application-form-group ${formErrors.linkedin_url ? 'error' : ''}`}>
              <label htmlFor="linkedin_url">LinkedIn URL</label>
              <input
                type="url"
                id="linkedin_url"
                name="linkedin_url"
                value={formData.linkedin_url}
                onChange={handleInputChange}
              />
              {renderFieldError('linkedin_url')}
            </div>

            <div className={`application-form-group ${formErrors.years_of_experience ? 'error' : ''}`}>
              <label htmlFor="years_of_experience">Years of Experience</label>
              <input
                type="number"
                id="years_of_experience"
                name="years_of_experience"
                value={formData.years_of_experience}
                onChange={handleInputChange}
                min="0"
                max="50"
              />
              {renderFieldError('years_of_experience')}
            </div>
          </div>

          <div className="application-form-section">
            <h2>Additional Information</h2>
            <div className={`application-form-group ${formErrors.why_pomu ? 'error' : ''}`}>
              <label htmlFor="why_pomu" className="application-required-field">
                Why do you want to join Pomu?
              </label>
              <textarea
                id="why_pomu"
                name="why_pomu"
                value={formData.why_pomu}
                onChange={handleInputChange}
                required
                rows="5"
              />
              {renderFieldError('why_pomu')}
            </div>

            <div className={`application-form-group ${formErrors.notes ? 'error' : ''}`}>
              <label htmlFor="notes">Additional Notes</label>
              <textarea
                id="notes"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
                rows="3"
                placeholder="Any additional information you'd like to share..."
              />
              {renderFieldError('notes')}
            </div>
          </div>

          <div className="application-submit-section">
            <button 
              type="submit" 
              className="application-submit-button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Application'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Application;