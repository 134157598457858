import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import useAPIService from './APIService';
import { format } from 'date-fns';
import { cacheService } from '../cacheService';
import { ArrowLeft, Trash2 } from 'lucide-react';
import './styles/SubmissionHistory.css';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    Loading your designs...
  </div>
);

const EmptyState = ({ onCreateNew }) => (
  <div className="empty-state">
    <p>No submissions found. Start by uploading a design!</p>
    <button onClick={onCreateNew} className="browse-button">
      Create New Design
    </button>
  </div>
);

const SubmissionCard = ({ submission, onDelete, onClick }) => (
  <div className="submission-card" onClick={onClick}>
    <img 
      src={`data:image/jpeg;base64,${submission.user_image}`}
      alt=""
      className="card-image"
    />
    <button 
      className="delete-button"
      onClick={(e) => {
        e.stopPropagation();
        onDelete(submission);
      }}
    >
      <Trash2 size={16} />
    </button>
    <div className="card-content">
      <div className="submission-prompt">{submission.user_prompt}</div>
      <div className="submission-date">
        {submission.timestamp && format(new Date(submission.timestamp), 'MMM d, yyyy')}
      </div>
    </div>
  </div>
);

const DeleteModal = ({ show, onClose, onConfirm, isDeleting }) => (
  <div className={`delete-modal ${show ? 'modal-show' : ''}`}>
    <div className="modal-content">
      <h3>Delete Submission</h3>
      <p>Are you sure you want to delete this submission? This action cannot be undone.</p>
      <div className="modal-buttons">
        <button 
          className="modal-button cancel-button"
          onClick={onClose}
          disabled={isDeleting}
        >
          Cancel
        </button>
        <button 
          className="modal-button confirm-delete"
          onClick={onConfirm}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </button>
      </div>
    </div>
  </div>
);

const SubmissionHistory = () => {
  const { setResults, setUserSubmittedImage, setUserSubmittedPrompt } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const apiService = useAPIService();
  
  const [submissions, setSubmissions] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasConfirmedNoSubmissions, setHasConfirmedNoSubmissions] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const shouldRefreshData = useCallback(() => {
    const hasNewSubmission = location.state?.submissionUpdated;
    const { isValid } = cacheService.getSubmissions();
    return hasNewSubmission || !isValid;
  }, [location.state]);

  const fetchSubmissions = useCallback(async (forceFetch = false) => {
    try {
      setIsLoading(true);
      setHasConfirmedNoSubmissions(false);

      if (!forceFetch) {
        const { data, isValid } = cacheService.getSubmissions();
        if (isValid) {
          setSubmissions(data);
          if (!shouldRefreshData()) {
            setIsLoading(false);
            setHasConfirmedNoSubmissions(data.length === 0);
            return;
          }
        }
      }
      
      const response = await apiService.GetUserSubmissions();
      
      if (response?.submissions) {
        setSubmissions(response.submissions);
        cacheService.setSubmissions(response.submissions);
        setHasConfirmedNoSubmissions(response.submissions.length === 0);
      }
    } catch (err) {
      console.error('Error fetching submissions:', err);
      setSubmissions([]);
      setHasConfirmedNoSubmissions(true);
    } finally {
      setIsLoading(false);
      
      if (location.state?.submissionUpdated) {
        window.history.replaceState(
          { ...location.state, submissionUpdated: false },
          document.title
        );
      }
    }
  }, [apiService, shouldRefreshData, location.state]);

  useEffect(() => {
    let mounted = true;

    const loadData = async () => {
      if (mounted) {
        const forceFetch = shouldRefreshData();
        await fetchSubmissions(forceFetch);
      }
    };

    loadData();

    return () => {
      mounted = false;
    };
  }, [fetchSubmissions, shouldRefreshData]);

  const handleSubmissionSelect = (submission) => {
    setResults(submission.matched_manufacturers);
    setUserSubmittedImage(submission.user_image);
    setUserSubmittedPrompt(submission.user_prompt);
    navigate('/manufacturer-results', { 
      state: { 
        from: 'history',
        submissionDate: submission.timestamp
      } 
    });
  };

  const handleDeleteClick = (submission) => {
    setSubmissionToDelete(submission);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!submissionToDelete) return;
    
    setIsDeleting(true);
    try {
      await apiService.DeleteSubmission(submissionToDelete.submission_id);
      
      const updatedSubmissions = submissions.filter(
        sub => sub.submission_id !== submissionToDelete.submission_id
      );
      setSubmissions(updatedSubmissions);
      cacheService.setSubmissions(updatedSubmissions);
      
      if (updatedSubmissions.length === 0) {
        setHasConfirmedNoSubmissions(true);
      }
    } catch (error) {
      console.error('Failed to delete submission:', error);
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setSubmissionToDelete(null);
    }
  };

  if (isLoading || submissions === null) {
    return <LoadingSpinner />;
  }

  return (
    <div className="history-container">

      {hasConfirmedNoSubmissions ? (
        <EmptyState onCreateNew={() => navigate('/sketch')} />
      ) : (
        <div className="submissions-grid">
          {submissions.map((submission) => (
            <SubmissionCard
              key={submission.submission_id}
              submission={submission}
              onDelete={handleDeleteClick}
              onClick={() => handleSubmissionSelect(submission)}
            />
          ))}
        </div>
      )}

      <DeleteModal
        show={showDeleteModal}
        onClose={() => {
          if (!isDeleting) {
            setShowDeleteModal(false);
            setSubmissionToDelete(null);
          }
        }}
        onConfirm={handleDeleteConfirm}
        isDeleting={isDeleting}
      />
    </div>
  );
};

export default SubmissionHistory;