import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import "../../styles/Test.css";
import { cacheService } from "../../../cacheService";

const swirl1 = "https://c.animaapp.com/pAZBMFQ8/img/mask-group-13@2x.png";
const swirl2 = "https://c.animaapp.com/pAZBMFQ8/img/mask-group-14@2x.png";
const swirl3 = "https://c.animaapp.com/pAZBMFQ8/img/mask-group-12@2x.png";

const styles = {
  cardContent: {
    color: '#1A1A1A',
  },
  title: {
    color: '#0C0A10',
    marginBottom: '12px',
    fontSize: '24px',
    fontWeight: '500',
  },
  description: {
    color: '#333333',
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: '#8029ff',
    color: '#FFFFFF',
    padding: '10px 24px',
    borderRadius: '63px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '15px',
    fontWeight: '500',
    fontFamily: '"Uncut Sans", sans-serif',
  }
};

const Sliders = () => {
  const { isAuthenticated } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  
  // Add refs for touch handling
  const touchStartX = useRef(null);
  const touchStartY = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleGetStarted = (route, state) => {
    scrollToTop();
    
    cacheService.invalidateListCache();
    cacheService.invalidateListPageState();
    
    cacheService.setListPageState({
      locationPart: "",
      typePart: "",
      searchMode: state?.previousMode || "manufacturers",
      currentPage: 1
    });
    
    navigate(route, { state });
  };
  
  const cards = [
    {
      img: swirl1,
      title: "Textile Database",
      description: "Access our comprehensive database of textiles, fabrics, and materials. Browse through an extensive collection of materials to find the perfect match for your project.",
      route: isAuthenticated ? "/manufacturers" : "/AuthPage",
      state: { 
        previousMode: "fabrics",
        initialMode: "fabrics"
      }
    },
    {
      img: swirl2,
      title: "Search For Manufacturers",
      description: "Find the perfect manufacturing partner for your needs. Browse through our curated list of verified manufacturers and connect with the ones that match your requirements.",
      route: isAuthenticated ? "/manufacturers" : "/AuthPage",
      state: {
        previousMode: "manufacturers",
        initialMode: "manufacturers"
      }
    },
    {
      img: swirl3,
      title: "Match With A Manufacturer",
      description: "Let our AI-powered system connect you with manufacturers that best fit your specific requirements. Get personalized recommendations based on your project needs.",
      route: isAuthenticated ? "/sketch" : "/AuthPage",
    },
  ];

  function getPositionClass(index) {
    switch (activeIndex) {
      case 0:
        if (index === 0) return "phone-center";
        if (index === 1) return "phone-right";
        if (index === 2) return "phone-left";
        break;
      case 1:
        if (index === 1) return "phone-center";
        if (index === 2) return "phone-right";
        if (index === 0) return "phone-left";
        break;
      case 2:
        if (index === 2) return "phone-center";
        if (index === 0) return "phone-right";
        if (index === 1) return "phone-left";
        break;
      default:
        return "";
    }
  }

  const handleCardClick = (index) => {
    setActiveIndex(index);
  };

  // Add touch event handlers
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e) => {
    if (!touchStartX.current || !touchStartY.current) return;

    const touchEndX = e.touches[0].clientX;
    const touchEndY = e.touches[0].clientY;

    const deltaX = touchStartX.current - touchEndX;
    const deltaY = touchStartY.current - touchEndY;

    // Only handle horizontal swipes (ignore if vertical movement is greater)
    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      e.preventDefault(); // Prevent scrolling when swiping horizontally

      if (Math.abs(deltaX) > 50) { // Minimum swipe distance threshold
        if (deltaX > 0) {
          // Swipe left
          setActiveIndex((prev) => (prev === cards.length - 1 ? 0 : prev + 1));
        } else {
          // Swipe right
          setActiveIndex((prev) => (prev === 0 ? cards.length - 1 : prev - 1));
        }
        // Reset touch coordinates after handling swipe
        touchStartX.current = null;
        touchStartY.current = null;
      }
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = null;
    touchStartY.current = null;
  };

  return (
    <div 
      className="cards-wrapper"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {cards.map((card, idx) => {
        const positionClass = getPositionClass(idx);
        const isCenter = positionClass === "phone-center";

        return (
          <div
            key={idx}
            className={`phone-card ${positionClass}`}
            onClick={() => handleCardClick(idx)}
          >
            <div className="circle-image">
              <img src={card.img} alt={card.title} />
            </div>

            <div className="card-content" style={styles.cardContent}>
              <h2 style={styles.title}>{card.title}</h2>
              <p style={styles.description}>{card.description}</p>

              <button
                style={{
                  ...styles.button,
                  opacity: isCenter ? 1 : 0,
                  visibility: isCenter ? 'visible' : 'hidden'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isCenter) handleGetStarted(card.route, card.state);
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Sliders;