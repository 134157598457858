import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Upload, ArrowRight, X, Check } from 'lucide-react';
import Pica from 'pica';
import { AppContext } from '../AppContext';
import { cacheService } from '../cacheService';
import useAPIService from './APIService';
import DuplicateDialog from './DuplicateDialog';
import './styles/SketchForm.css';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB in bytes
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png', 'application/pdf'];
const TARGET_IMAGE_DIMENSION = 512;

const SketchForm = () => {
  const navigate = useNavigate();
  const apiService = useAPIService();
  
  const { 
    isAuthenticated, 
    isAuthChecking,
    userEmail,
    setResults,
    setUserSubmittedImage,
    setUserSubmittedPrompt,
    clearResults
  } = useContext(AppContext);

  // State management
  const [fileState, setFileState] = useState({
    clipFile: null,
    clipFileBase64: null,
    pendingFile: null,
    pendingBase64Image: null,
    fileName: '' // Added fileName to state
  });

  const [uiState, setUiState] = useState({
    prompt: '',
    error: '',
    isLoading: false
  });

  const [dialogState, setDialogState] = useState({
    isDuplicateDialogOpen: false,
    duplicateDetails: null
  });

  useEffect(() => {
    if (!isAuthenticated && !isAuthChecking) {
      navigate('/home');
      return;
    }
    clearResults();
  }, [isAuthenticated, isAuthChecking, navigate, clearResults]);

  const resizeImage = async (file) => {
    const pica = new Pica();
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = async () => {
        try {
          const canvas = document.createElement('canvas');
          canvas.width = TARGET_IMAGE_DIMENSION;
          canvas.height = TARGET_IMAGE_DIMENSION;

          await pica.resize(img, canvas, {
            filter: 'lanczos3',
            alpha: true,
          });
          resolve(canvas);
        } catch (error) {
          reject(error);
        }
      };
      img.onerror = () => reject(new Error('Failed to load image'));
      img.src = URL.createObjectURL(file);
    });
  };

  const convertToBase64 = (canvas) => {
    return new Promise((resolve, reject) => {
      try {
        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Failed to create blob from canvas'));
            return;
          }
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.onerror = (e) => reject(new Error(`FileReader error: ${e.target.error}`));
          reader.readAsDataURL(blob);
        }, 'image/jpeg');
      } catch (error) {
        reject(error);
      }
    });
  };

  const checkForDuplicate = async (base64Image) => {
    try {
      const checkData = new FormData();
      checkData.append('image', base64Image);
      checkData.append('userEmail', userEmail);
      
      const result = await apiService.CheckDuplicateImage(checkData);
      
      if (result.is_duplicate) {
        setDialogState({
          isDuplicateDialogOpen: true,
          duplicateDetails: result.submission_details
        });
        setFileState(prev => ({
          ...prev,
          pendingBase64Image: base64Image
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error checking for duplicate:', error);
      return false;
    }
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    
    if (!file) return;

    if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
      setUiState(prev => ({
        ...prev,
        error: 'Please upload a JPEG, PNG, or PDF file.'
      }));
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setUiState(prev => ({
        ...prev,
        error: 'File size exceeds 10MB limit.'
      }));
      return;
    }

    try {
      const resizedImage = await resizeImage(file);
      const base64Image = await convertToBase64(resizedImage);
      
      setFileState(prev => ({
        ...prev,
        pendingFile: file,
        fileName: file.name // Store the filename
      }));

      const isDuplicate = await checkForDuplicate(base64Image);
      
      if (!isDuplicate) {
        setFileState(prev => ({
          ...prev,
          clipFile: [file],
          clipFileBase64: base64Image,
          pendingFile: null,
          fileName: file.name // Maintain filename
        }));
        setUiState(prev => ({
          ...prev,
          error: ''
        }));
      }
    } catch (error) {
      setUiState(prev => ({
        ...prev,
        error: 'Error processing image. Please try again.'
      }));
      setFileState(prev => ({
        ...prev,
        pendingFile: null,
        fileName: '' // Clear filename on error
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!fileState.clipFileBase64) {
      setUiState(prev => ({
        ...prev,
        error: 'Please upload an image'
      }));
      return;
    }

    if (!uiState.prompt.trim()) {
      setUiState(prev => ({
        ...prev,
        error: 'Please enter keywords to describe what you are looking for'
      }));
      return;
    }

    setUiState(prev => ({
      ...prev,
      isLoading: true,
      error: ''
    }));

    const formData = new FormData();
    formData.append('image', fileState.clipFileBase64);
    formData.append('prompt', uiState.prompt);
    formData.append('userEmail', userEmail);

    try {
      const response = await apiService.SubmitTechPack(formData);
      
      if (!response) {
        throw new Error('No response received');
      }

      cacheService.invalidateSubmissionsCache();
      setResults(response);
      setUserSubmittedImage(fileState.clipFileBase64);
      setUserSubmittedPrompt(uiState.prompt);
      
      navigate('/manufacturer-results', { 
        state: { 
          from: 'sketch',
          submissionUpdated: true
        } 
      });
    } catch (error) {
      setUiState(prev => ({
        ...prev,
        error: 'Failed to submit. Please try again.',
        isLoading: false
      }));
    }
  };

  const handleDuplicateDialogClose = () => {
    setDialogState({
      isDuplicateDialogOpen: false,
      duplicateDetails: null
    });
    setFileState(prev => ({
      ...prev,
      pendingBase64Image: null,
      pendingFile: null,
      fileName: '' // Clear filename
    }));
  };

  const handleDuplicateDialogConfirm = () => {
    setDialogState({
      isDuplicateDialogOpen: false,
      duplicateDetails: null
    });
    if (fileState.pendingFile && fileState.pendingBase64Image) {
      setFileState(prev => ({
        ...prev,
        clipFile: [fileState.pendingFile],
        clipFileBase64: fileState.pendingBase64Image,
        pendingFile: null,
        pendingBase64Image: null
      }));
    }
  };

  const handleRemoveFile = () => {
    setFileState({
      clipFile: null,
      clipFileBase64: null,
      pendingFile: null,
      pendingBase64Image: null,
      fileName: ''
    });
    setUiState(prev => ({
      ...prev,
      error: ''
    }));
  };

  const renderUploadPreview = () => {
    if (!fileState.clipFileBase64) return null;
    
    return (
      <div className="upload-preview">
        <div className="file-info">
          <Check size={14} className="check-icon" />
          <span className="file-name">{fileState.fileName}</span>
          <button 
            type="button" 
            className="remove-file"
            onClick={handleRemoveFile}
            aria-label="Remove file"
          >
            <X size={14} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="sketch-form-container">
      <h1 className="sketch-form-title">
        Get started! Upload an image and let the magic happen
      </h1>

      <form onSubmit={handleSubmit} className="sketch-form">
        <div className="input-group">
          <button
            type="button"
            onClick={() => document.getElementById('file-upload').click()}
            className="upload-button"
          >
            <Upload size={20} />
            Upload image
          </button>
          
          <div className="prompt-input-wrapper">
            <input
              type="text"
              value={uiState.prompt}
              onChange={(e) => setUiState(prev => ({ ...prev, prompt: e.target.value }))}
              placeholder="Enter keywords to describe what you are looking for"
              className="keyword-input"
            />
            <button 
              type="submit" 
              className="submit-button"
              disabled={!fileState.clipFileBase64 || !uiState.prompt.trim() || uiState.isLoading}
            >
              {uiState.isLoading ? (
                <div className="sketch-form-button-spinner" />
              ) : (
                <ArrowRight size={20} />
              )}
            </button>
          </div>
        </div>

        {fileState.clipFileBase64 && (
          <div className="upload-preview">
            <div className="file-info">
              <Check size={14} className="check-icon" />
              <span className="file-name">{fileState.fileName}</span>
              <button 
                type="button" 
                className="remove-file"
                onClick={handleRemoveFile}
                aria-label="Remove file"
              >
                <X size={14} />
              </button>
            </div>
          </div>
        )}

        <input
          id="file-upload"
          type="file"
          accept={ACCEPTED_FILE_TYPES.join(',')}
          onChange={handleFileUpload}
          className="file-input"
        />

        <p className="help-text">
          Image files should be in JPEG, PNG, PDF formats. Maximum size is 10MB
        </p>

        {uiState.error && (
          <p className="error-message">{uiState.error}</p>
        )}

        {uiState.isLoading && (
          <div className="sketch-form-loading-overlay">
            <div className="sketch-form-spinner" />
          </div>
        )}

        <DuplicateDialog
          isOpen={dialogState.isDuplicateDialogOpen}
          details={dialogState.duplicateDetails}
          onCancel={handleDuplicateDialogClose}
          onConfirm={handleDuplicateDialogConfirm}
          className="duplicate-dialog"
          overlayClassName="duplicate-dialog-overlay"
        />
      </form>
    </div>
  );
};

export default SketchForm;