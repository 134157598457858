
import React, { useContext } from 'react';
import GlobalNavbar from './GlobalNavbar';
import GlobalFooter from './GlobalFooter';
import { AppContext } from '../AppContext';

const Layout = ({ children }) => {
  const { isDark } = useContext(AppContext);

  return (
    <div>
      <GlobalNavbar />
      {children}
      <GlobalFooter />
    </div>
  );
};

export default Layout;