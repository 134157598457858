export const COUNTRY_COORDINATES = {
    // North America
    "United States": { lat: 37.0902, lng: -95.7129, aliases: ["USA", "US", "United States of America"] },
    "Canada": { lat: 56.1304, lng: -106.3468 },
    "Mexico": { lat: 23.6345, lng: -102.5528 },
  
    // South America
    "Brazil": { lat: -14.2350, lng: -51.9253 },
    "Argentina": { lat: -38.4161, lng: -63.6167 },
    "Chile": { lat: -35.6751, lng: -71.5430 },
    "Colombia": { lat: 4.5709, lng: -74.2973 },
    "Peru": { lat: -9.1900, lng: -75.0152 },
    "Venezuela": { lat: 6.4238, lng: -66.5897 },
  
    // Europe
    "United Kingdom": { lat: 55.3781, lng: -3.4360, aliases: ["UK", "Great Britain"] },
    "Germany": { lat: 51.1657, lng: 10.4515, aliases: ["Deutschland"] },
    "France": { lat: 46.2276, lng: 2.2137 },
    "Italy": { lat: 41.8719, lng: 12.5674 },
    "Spain": { lat: 40.4637, lng: -3.7492 },
    "Portugal": { lat: 39.3999, lng: -8.2245 },
    "Netherlands": { lat: 52.1326, lng: 5.2913, aliases: ["Holland"] },
    "Belgium": { lat: 50.8503, lng: 4.3517 },
    "Switzerland": { lat: 46.8182, lng: 8.2275 },
    "Austria": { lat: 47.5162, lng: 14.5501 },
    "Sweden": { lat: 60.1282, lng: 18.6435 },
    "Norway": { lat: 60.4720, lng: 8.4689 },
    "Denmark": { lat: 56.2639, lng: 9.5018 },
    "Finland": { lat: 61.9241, lng: 25.7482 },
    "Ireland": { lat: 53.1424, lng: -7.6921 },
    "Poland": { lat: 51.9194, lng: 19.1451 },
    "Ukraine": { lat: 48.3794, lng: 31.1656 },
    "Greece": { lat: 39.0742, lng: 21.8243 },
    "Romania": { lat: 45.9432, lng: 24.9668 },
    "Czech Republic": { lat: 49.8175, lng: 15.4730, aliases: ["Czechia"] },
    "Hungary": { lat: 47.1625, lng: 19.5033 },
  
    // Asia
    "China": { lat: 35.8617, lng: 104.1954 },
    "Japan": { lat: 36.2048, lng: 138.2529 },
    "South Korea": { lat: 35.9078, lng: 127.7669, aliases: ["Korea, South", "Republic of Korea"] },
    "North Korea": { lat: 40.3399, lng: 127.5101, aliases: ["Korea, North", "Democratic People's Republic of Korea"] },
    "India": { lat: 20.5937, lng: 78.9629 },
    "Indonesia": { lat: -0.7893, lng: 113.9213 },
    "Pakistan": { lat: 30.3753, lng: 69.3451 },
    "Bangladesh": { lat: 23.6850, lng: 90.3563 },
    "Vietnam": { lat: 14.0583, lng: 108.2772 },
    "Thailand": { lat: 15.8700, lng: 100.9925 },
    "Taiwan": { lat: 23.5937, lng: 121.0254 },
    "Malaysia": { lat: 4.2105, lng: 101.9758 },
    "Singapore": { lat: 1.3521, lng: 103.8198 },
    "Philippines": { lat: 12.8797, lng: 121.7740 },
    "Saudi Arabia": { lat: 23.8859, lng: 45.0792 },
    "Iran": { lat: 32.4279, lng: 53.6880 },
    "Iraq": { lat: 33.2232, lng: 43.6793 },
    "Israel": { lat: 31.0461, lng: 34.8516 },
    "Turkey": { lat: 38.9637, lng: 35.2433 },
  
    // Africa
    "Egypt": { lat: 26.8206, lng: 30.8025 },
    "South Africa": { lat: -30.5595, lng: 22.9375 },
    "Nigeria": { lat: 9.0820, lng: 8.6753 },
    "Kenya": { lat: -0.0236, lng: 37.9062 },
    "Morocco": { lat: 31.7917, lng: -7.0926 },
    "Ethiopia": { lat: 9.1450, lng: 40.4897 },
    "Ghana": { lat: 7.9465, lng: -1.0232 },
    "Tanzania": { lat: -6.3690, lng: 34.8888 },
    "Algeria": { lat: 36.7372, lng: 3.0863 },
  
    // Oceania
    "Australia": { lat: -25.2744, lng: 133.7751 },
    "New Zealand": { lat: -40.9006, lng: 174.8860 },
    "Papua New Guinea": { lat: -6.3150, lng: 143.9555 },
    "Fiji": { lat: -17.7134, lng: 178.0650 },
  
    // Central America & Caribbean
    "Guatemala": { lat: 15.7835, lng: -90.2308 },
    "Cuba": { lat: 21.5218, lng: -77.7812 },
    "Haiti": { lat: 18.9712, lng: -72.2852 },
    "Dominican Republic": { lat: 18.7357, lng: -70.1627 },
    "Costa Rica": { lat: 9.7489, lng: -83.7534 },
    "Panama": { lat: 8.5380, lng: -80.7821 },
  
    // Additional Important Territories
    "Hong Kong": { lat: 22.3193, lng: 114.1694 },
    "Macau": { lat: 22.1987, lng: 113.5439 },
    "Puerto Rico": { lat: 18.2208, lng: -66.5901 }
  };
  
  // Normalize country names (handle variations, remove special characters, etc.)
  export const normalizeCountryName = (name) => {
    if (!name) return null;
    
    // Convert to lowercase and remove extra spaces
    const normalized = name.toLowerCase().trim();
    
    // Check direct matches first
    for (const [country, data] of Object.entries(COUNTRY_COORDINATES)) {
      if (country.toLowerCase() === normalized) {
        return country;
      }
      
      // Check aliases if they exist
      if (data.aliases && data.aliases.some(alias => alias.toLowerCase() === normalized)) {
        return country;
      }
    }
    
    // Handle special cases
    const specialCases = {
      'usa': 'United States',
      'uk': 'United Kingdom',
      'holland': 'Netherlands',
      'republic of korea': 'South Korea',
      'korea': 'South Korea',
      'mainland china': 'China',
      'chinese taipei': 'Taiwan',
    };
    
    return specialCases[normalized] || null;
  };
  
  // Get coordinates for a country
  export const getCountryCoordinates = (countryName) => {
    const normalizedName = normalizeCountryName(countryName);
    return normalizedName ? COUNTRY_COORDINATES[normalizedName] : null;
  };