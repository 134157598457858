import React from "react";
import styles from "./styles.module.scss";
import { Col, Row } from "react-bootstrap";
import image from "../../images/auth/auth.png";
import SignIn from "./SignIn/SignIn";

const Auth = () => {
  return (
    <Row className={styles._auth}>
      <Col sm={6}>
        <div className={styles._auth_wrapper}>
          <div className={styles._auth_header}>
            <button className={styles._auth_back_button}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M12.0227 3.35225C12.2224 3.55195 12.2406 3.86445 12.0772 4.08466L12.0227 4.14775L7.17075 9L12.0227 13.8523C12.2224 14.052 12.2406 14.3645 12.0772 14.5847L12.0227 14.6477C11.823 14.8474 11.5105 14.8656 11.2903 14.7022L11.2273 14.6477L5.97725 9.39775C5.77755 9.19805 5.7594 8.88555 5.92279 8.66534L5.97725 8.60225L11.2273 3.35225C11.4469 3.13258 11.8031 3.13258 12.0227 3.35225Z"
                  fill="black"
                />
              </svg>
              Back
            </button>
          </div>

          <SignIn />
        </div>
      </Col>
      <Col sm={6}>
        <div className={styles._right_side}>
          <img src={image} alt="" className={styles._right_side_img} />
        </div>
      </Col>
    </Row>
  );
};

export default Auth;
