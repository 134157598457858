import React from "react";
import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";
import { contents } from "../JSON/home";

const Content = () => {
  return (
    <div className={styles._content}>
      <Container>
        <div className={styles._content_wrapper}>
          {contents?.map((content, index) => (
            <div key={index} className={styles._content_card}>
              <div className={styles._content_left}>
                <p className={styles._info}>Step {content?.id}</p>
                <h2 className={styles._title}>
                  {content?.title}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="81"
                    height="80"
                    viewBox="0 0 81 80"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M60.5315 20H20.5315V26.6667H49.1504L19.1504 56.6667L23.8645 61.3807L53.8648 31.3804V60H60.5315V20Z"
                      fill="black"
                    />
                  </svg>
                </h2>
                <p className={styles.description}>{content?.content}</p>
              </div>
              <div className={styles._content_right}>
                <img src={content?.image} alt="" className="" />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Content;
