import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <Container className="py-5 mt-5">
      <Row>
        <Col lg={8} className="mx-auto">
          <h1 className="mb-4">Terms of Service</h1>
          <p className="text">Last updated: October 2, 2024</p>
          
          <div className="terms-content mt-4">
            <p>By accessing or using the services provided by Pomu ("Company," "we," "our," or "us"), including our website and any related applications (collectively, the "Services"), you agree to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Services.</p>

            <p>Pomu provides a platform that connects fashion designers with manufacturers. Our Services include, but are not limited to, allowing users to upload design files, facilitating matches between designers and manufacturers, and providing related communication tools.</p>

            <p>To use certain features of our Services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.</p>

            <p>You retain all rights in, and are solely responsible for, the content you upload, submit, or display on or through our Services ("User Content"). By submitting User Content, you grant Pomu a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, and display such content for the purpose of providing and improving our Services.</p>

            <p>The Services and their original content, features, and functionality are owned by Pomu and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Services, except as permitted by these Terms.</p>

            <p>You agree not to use the Services:</p>
            <ol type="a">
              <li>In any way that violates any applicable federal, state, local, or international law or regulation.</li>
              <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent.</li>
              <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.</li>
              <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which may harm the Company or users of the Services.</li>
              <li>To create, produce, or facilitate the production of counterfeit products.</li>
            </ol>

            <p>Pomu does not condone the use of our platform to create or facilitate the production of counterfeit products. We are not responsible in any shape or form for any counterfeit activities conducted by users of our Services. Users found to be engaging in such activities may have their accounts terminated and may face legal consequences.</p>

            <p>We may terminate or suspend your account and bar access to the Services immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>

            <p>In no event shall Pomu, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Services.</p>

            <p>Your use of the Services is at your sole risk. The Services are provided on an "AS IS" and "AS AVAILABLE" basis. The Services are provided without warranties of any kind, whether express or implied.</p>

            <p>These Terms shall be governed and construed in accordance with the laws of Wyoming, without regard to its conflict of law provisions.</p>

            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect.</p>

            <p>If you have any questions about these Terms, please{' '}
              <Link to="/contactForm" style={{ color: 'blue', textDecoration: 'none' }}>
                contact us!
              </Link></p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfService;