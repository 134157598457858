// App.js
import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Amplify } from "aws-amplify";
import { Container, Spinner } from "react-bootstrap";

import awsExports from "./aws-exports";
import { AppProvider, AppContext } from "./AppContext";
import Layout from "./components/Layout";
import { AuthPage } from "./components/auth";
import PomuForm from "./components/PomuForm";
import SketchForm from "./components/SketchForm";
import MainPage from "./components/Home";
import ManufacturerResults from "./components/manufacture/ManufacturerResults";
import ManufacturerTemplate from "./components/manufacture/ManufacturerTemplate";
import ProfilePage from "./components/auth/ProfilePage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import ContactForm from "./components/ContactForm";
import ManufacturerList from "./components/manufacture/ManufacturerList";
import SubmissionHistory from "./components/SubmissionHistory.js";
import About from "./components/About.js";
import ManuContact from "./components/ManuContact.js";
import Auth from "./components/auth/Auth.jsx";
import Careers from "./components/Careers.js";
import Application from './components/Application';



Amplify.configure(awsExports);

function AppContent() {
  const { isAuthenticated, isAuthChecking, handleLogout } =
    useContext(AppContext);

  if (isAuthChecking) {
    return (
      <Container>
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{
            height: "100vh",
          }}
        >
          <Spinner animation="border" />
        </div>
      </Container>
    );
  }

  return (
    <Layout>
      <Routes>
        <Route path="/home" element={<MainPage />} />
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/AuthPage" element={<AuthPage />} />
        <Route path="/demo" element={<Auth />} />
        <Route path="/logout" element={<Logout onSignOut={handleLogout} />} />
        <Route path="/pomuform" element={<PomuForm />} />
        <Route path="/sketch" element={<SketchForm />} />
        <Route path="/manufacturer-results" element={<ManufacturerResults />} />
        <Route path="/manufacturers" element={<ManufacturerList />} />
        <Route path="/fabrics" element={<ManufacturerList />} />
        <Route
          path="/manufacturer/:manufacturerId"
          element={<ManufacturerTemplate />}
        />
        <Route path="/fabric/:fabricId" element={<ManufacturerTemplate />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/contactform" element={<ContactForm />} />
        <Route path="/history" element={<SubmissionHistory />} />
        <Route path="/about" element={<About />} />
        <Route path="/ManuContact" element={<ManuContact />} />
        <Route path="/Careers" element={<Careers />} />
        <Route path="/careers/apply/:positionId" element={<Application />} />
        
        <Route
          path="/profile"
          element={
            isAuthenticated ? (
              <ProfilePage />
            ) : (
              <Navigate to="/home" state={{ from: "/profile" }} />
            )
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

function App() {
  return (
    <Router>
      <AppProvider>
        <AppContent />
      </AppProvider>
    </Router>
  );
}

function Logout({ onSignOut }) {
  React.useEffect(() => {
    onSignOut();
  }, [onSignOut]);

  return <Navigate to="/home" />;
}

export default App;
