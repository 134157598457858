import React from "react";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import styles from "./styles.module.scss";
import { team } from "../JSON/team";
import "bootstrap/dist/css/bootstrap.min.css";

const Team = () => {
  return (
    <div className={styles._team}>
      <Container>
        <div>
          <div className={styles._about_wrapper}>
            <p className={styles._chip}>About Us</p>
            <h2 className={styles._header}>Our Team</h2>
            <p className={styles._description}>
              Combining creativity and technology our team is reimagining how
              the fashion production process works.
            </p>
          </div>

          <>
            <Row className={styles._team_wrapper} >
              {team?.map((member) => (
                <Col key={member.id} md={3} className={styles._team_member} >
                  <div className={styles._team_card}>
                    <img
                      src={member.image}
                      alt={member.name}
                      className={styles._team_member_image}
                    />
                    <h4 className={styles._team_heading} >{member.name}</h4>
                    <p className={styles._team_info} >{member.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </>
        </div>
      </Container>
    </div>
  );
};

export default Team;
