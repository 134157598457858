import React, { useRef, useMemo, useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { getCountryCoordinates, normalizeCountryName } from "./CountryData";

const MapController = ({ isMobile }) => {
  const map = useMap();

  useEffect(() => {
    if (isMobile) {
      map.setView([20, 0], 1);
    } else {
      map.setView([30, 0], 2);
    }
  }, [map, isMobile]);

  return null;
};

const UnifiedMap = ({ items = [], mode = "manufacturers" }) => {
  const mapRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const cleanLocation = (location) => {
    if (!location) return "";
    return location.replace(/\s*View exact location\s*/g, "").trim();
  };

  const extractCountry = (location) => {
    if (!location) return null;
    const cleanedLocation = cleanLocation(location);
    const parts = cleanedLocation.split(",");
    return parts.length > 1
      ? parts[parts.length - 1].trim()
      : cleanedLocation.trim();
  };

  const createCustomIcon = (count) => {
    const size = Math.min(30 + Math.min(count.toString().length * 8, 24), 50);
    const bgColor = mode === "manufacturers" ? "#854cff" : "#4CAF50";

    return L.divIcon({
      html: `
        <div style="
          background-color: ${bgColor};
          width: ${size}px;
          height: ${size}px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: white;
          font-weight: bold;
          font-family: 'Roboto', sans-serif;
          font-size: ${12 + Math.min(count.toString().length, 2)}px;
          border: 2px solid white;
          box-shadow: 0 2px 5px rgba(0,0,0,0.2);
        ">
          ${count}
        </div>
      `,
      className: "custom-marker",
      iconSize: L.point(size, size),
      iconAnchor: L.point(size / 2, size / 2),
    });
  };

  const groupedMarkers = useMemo(() => {
    if (!Array.isArray(items)) return [];

    const grouped = items.reduce((acc, item) => {
      const location =
        mode === "manufacturers" ? item.location : item.supplier?.location;

      if (!location) return acc;

      const country = extractCountry(location);
      const normalizedCountry = normalizeCountryName(country);

      if (!normalizedCountry) return acc;

      if (!acc[normalizedCountry]) {
        const coordinates = getCountryCoordinates(normalizedCountry);
        if (!coordinates) return acc;

        acc[normalizedCountry] = {
          items: [],
          count: 0,
          coordinates,
          countryName: normalizedCountry,
          regions: new Set(),
        };
      }

      const cleanedLocation = cleanLocation(location);
      const region = cleanedLocation.split(",")[0]?.trim();
      if (region) {
        acc[normalizedCountry].regions.add(region);
      }

      acc[normalizedCountry].items.push({
        ...item,
        location: cleanedLocation,
      });
      acc[normalizedCountry].count += 1;
      return acc;
    }, {});

    return Object.values(grouped);
  }, [items, mode]);

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <MapContainer
        center={[30, 0]}
        zoom={2}
        style={{ height: "100%", width: "100%" }}
        ref={mapRef}
        zoomControl={false}
        attributionControl={false}
        dragging={isMobile}
        touchZoom={isMobile}
        doubleClickZoom={isMobile}
        scrollWheelZoom={false}
        boxZoom={false}
        keyboard={false}
        tap={isMobile}
        minZoom={isMobile ? 1 : 2}
        maxZoom={isMobile ? 8 : 2}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        maxBoundsViscosity={1.0}
      >
        <MapController isMobile={isMobile} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />

        {groupedMarkers.map((marker) => (
          <Marker
            key={`${marker.countryName}-${marker.count}-${marker.items.length}`}
            position={[marker.coordinates.lat, marker.coordinates.lng]}
            icon={createCustomIcon(marker.count)}
          >
            <Popup>
              <div className="p-2" style={{ fontFamily: "Roboto, sans-serif" }}>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    marginBottom: "4px",
                    color: "#000828",
                  }}
                >
                  {marker.countryName}
                </div>
                <div
                  style={{
                    color: "#666",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  {marker.count}{" "}
                  {mode === "manufacturers" ? "manufacturer" : "supplier"}
                  {marker.count !== 1 ? "s" : ""}
                </div>
                {marker.regions && marker.regions.size > 0 && (
                  <div
                    style={{
                      color: "#666",
                      fontSize: "13px",
                      marginBottom: "8px",
                    }}
                  >
                    Regions: {Array.from(marker.regions).join(", ")}
                  </div>
                )}
                <div
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    borderTop: "1px solid #f0effb",
                    marginTop: "8px",
                  }}
                >
                  {marker.items.map((item) => (
                    <div
                      key={item.id || `${item.name}-${item.location}`}
                      style={{
                        padding: "4px 0",
                        fontSize: "13px",
                        borderBottom: "1px solid #f0effb",
                      }}
                    >
                      {item.name} - {item.location.split(",")[0].trim()}
                      {mode === "fabrics" && (
                        <div style={{ fontSize: "12px", color: "#666" }}>
                          {item.composition}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default React.memo(UnifiedMap);

// import React, { useRef, useMemo } from 'react';
// import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import L from 'leaflet';
// import 'leaflet/dist/leaflet.css';
// import { getCountryCoordinates, normalizeCountryName } from './CountryData';

// const UnifiedMap = ({ items = [], mode = 'manufacturers' }) => {
//   const mapRef = useRef(null);

//   const cleanLocation = (location) => {
//     if (!location) return '';
//     return location.replace(/\s*View exact location\s*/g, '').trim();
//   };

//   const extractCountry = (location) => {
//     if (!location) return null;
//     const cleanedLocation = cleanLocation(location);
//     const parts = cleanedLocation.split(',');
//     return parts.length > 1 ? parts[parts.length - 1].trim() : cleanedLocation.trim();
//   };

//   const createCustomIcon = (count) => {
//     const size = Math.min(30 + Math.min(count.toString().length * 8, 24), 50);
//     // Use different colors for manufacturers vs fabrics
//     const bgColor = mode === 'manufacturers' ? '#854cff' : '#4CAF50';

//     return L.divIcon({
//       html: `
//         <div style="
//           background-color: ${bgColor};
//           width: ${size}px;
//           height: ${size}px;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           border-radius: 50%;
//           color: white;
//           font-weight: bold;
//           font-family: 'Roboto', sans-serif;
//           font-size: ${12 + Math.min(count.toString().length, 2)}px;
//           border: 2px solid white;
//           box-shadow: 0 2px 5px rgba(0,0,0,0.2);
//         ">
//           ${count}
//         </div>
//       `,
//       className: 'custom-marker',
//       iconSize: L.point(size, size),
//       iconAnchor: L.point(size/2, size/2)
//     });
//   };

//   const groupedMarkers = useMemo(() => {
//     if (!Array.isArray(items)) return [];

//     const grouped = items.reduce((acc, item) => {
//       // Get location based on mode
//       const location = mode === 'manufacturers'
//         ? item.location
//         : item.supplier?.location;

//       if (!location) return acc;

//       const country = extractCountry(location);
//       const normalizedCountry = normalizeCountryName(country);

//       if (!normalizedCountry) return acc;

//       if (!acc[normalizedCountry]) {
//         const coordinates = getCountryCoordinates(normalizedCountry);
//         if (!coordinates) return acc;

//         acc[normalizedCountry] = {
//           items: [],
//           count: 0,
//           coordinates,
//           countryName: normalizedCountry,
//           regions: new Set()
//         };
//       }

//       const cleanedLocation = cleanLocation(location);
//       const region = cleanedLocation.split(',')[0]?.trim();
//       if (region) {
//         acc[normalizedCountry].regions.add(region);
//       }

//       acc[normalizedCountry].items.push({
//         ...item,
//         location: cleanedLocation
//       });
//       acc[normalizedCountry].count += 1;
//       return acc;
//     }, {});

//     return Object.values(grouped);
//   }, [items, mode]);

//   return (
//     <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
//       <MapContainer
//         center={[30, 0]}
//         zoom={2}
//         style={{ height: '100%', width: '100%' }}
//         ref={mapRef}
//         zoomControl={false}
//         attributionControl={false}
//         dragging={false}
//         touchZoom={false}
//         doubleClickZoom={false}
//         scrollWheelZoom={false}
//         boxZoom={false}
//         keyboard={false}
//         tap={false}
//         minZoom={2}
//         maxZoom={2}
//         maxBounds={[[-90, -180], [90, 180]]}
//         maxBoundsViscosity={1.0}
//       >
//         <TileLayer
//           url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
//           attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
//         />

//         {groupedMarkers.map((marker) => (
//           <Marker
//             key={`${marker.countryName}-${marker.count}-${marker.items.length}`}
//             position={[marker.coordinates.lat, marker.coordinates.lng]}
//             icon={createCustomIcon(marker.count)}
//           >
//             <Popup>
//               <div className="p-2" style={{ fontFamily: 'Roboto, sans-serif' }}>
//                 <div style={{
//                   fontWeight: 600,
//                   fontSize: '16px',
//                   marginBottom: '4px',
//                   color: '#000828'
//                 }}>
//                   {marker.countryName}
//                 </div>
//                 <div style={{
//                   color: '#666',
//                   fontSize: '14px',
//                   marginBottom: '8px'
//                 }}>
//                   {marker.count} {mode === 'manufacturers' ? 'manufacturer' : 'supplier'}
//                   {marker.count !== 1 ? 's' : ''}
//                 </div>
//                 {marker.regions && marker.regions.size > 0 && (
//                   <div style={{
//                     color: '#666',
//                     fontSize: '13px',
//                     marginBottom: '8px'
//                   }}>
//                     Regions: {Array.from(marker.regions).join(', ')}
//                   </div>
//                 )}
//                 <div style={{
//                   maxHeight: '150px',
//                   overflowY: 'auto',
//                   borderTop: '1px solid #f0effb',
//                   marginTop: '8px'
//                 }}>
//                   {marker.items.map((item) => (
//                     <div
//                       key={item.id || `${item.name}-${item.location}`}
//                       style={{
//                         padding: '4px 0',
//                         fontSize: '13px',
//                         borderBottom: '1px solid #f0effb'
//                       }}
//                     >
//                       {item.name} - {item.location.split(',')[0].trim()}
//                       {mode === 'fabrics' && (
//                         <div style={{ fontSize: '12px', color: '#666' }}>
//                           {item.composition}
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </Popup>
//           </Marker>
//         ))}
//       </MapContainer>
//     </div>
//   );
// };

// export default React.memo(UnifiedMap);
