import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Button, Image, Dropdown } from 'react-bootstrap';
import { LogOut, User } from 'lucide-react';
import { AppContext } from '../AppContext';
import './styles/GlobalNavbar.css';

const GlobalNavbar = () => {
  const { 
    isAuthenticated, 
    handleLogout,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const navLinks = useMemo(() => [
    { path: '/home', label: 'Home', showAlways: true },
    {
      label: 'Services',
      requireAuth: true,
      isDropdown: true,
      items: [
        { path: '/sketch', label: 'Upload' },
        { path: '/manufacturers', label: 'Manufacturer Search' },
        { path: '/history', label: 'Submissions' },
      ]
    },
    {
      label: 'Our Team',
      showAlways: true,
      isDropdown: true,
      items: [
        { path: '/about', label: 'About' },
        { path: '/Careers', label: 'Careers' },
      ]
    },
    { path: '/ManuContact', label: 'For Manufacturers', requireAuth: true },
  ], []);

  const filteredNavLinks = navLinks.filter(link => 
    link.showAlways || (link.requireAuth === isAuthenticated)
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleLogin = () => {
    scrollToTop();
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(() => {
      navigate('/AuthPage');
      setIsAnimating(false);
    }, 200);
  };

  const handleLogoutAndRedirect = async () => {
    scrollToTop();
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(async () => {
      await handleLogout();
      navigate('/home');
      setIsAnimating(false);
    }, 200);
  };

  const handleNavLinkClick = () => {
    scrollToTop();
    setIsAnimating(true);
    setIsOpen(false);
    setTimeout(() => {
      setIsAnimating(false);
    }, 200);
  };

  const logoSrc = require("../images/logo/pomu-logo-invert.png");

  const handleMenuToggle = () => {
    setIsAnimating(true);
    setIsOpen(!isOpen);
    setTimeout(() => {
      setIsAnimating(false);
    }, 200);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }

    return () => {
      document.body.classList.remove('menu-open');
    };
  }, [isOpen]);

  const renderNavItem = (link) => {
    const isMobile = window.innerWidth < 992;
  
    if (link.isDropdown) {
      // Mobile: Use Bootstrap Dropdown with click behavior
      if (isMobile) {
        return (
          <Dropdown key={link.label} className="nav-item-dropdown">
            <Dropdown.Toggle className="nav-link-custom dropdown-toggle-nav">
              {link.label}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-nav">
              {link.items.map((item) => (
                <Dropdown.Item
                  key={item.path}
                  as={Link}
                  to={item.path}
                  onClick={handleNavLinkClick}
                  className="dropdown-item-custom"
                  active={location.pathname === item.path}
                >
                  {item.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      }
      
      // Desktop: Use custom hover-only dropdown
      return (
        <div key={link.label} className="nav-item-dropdown">
          <div className="nav-link-custom dropdown-toggle-nav">
            {link.label}
          </div>
          <div className="dropdown-menu-nav">
            {link.items.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                onClick={handleNavLinkClick}
                className="dropdown-item-custom"
                style={{
                  backgroundColor: location.pathname === item.path ? 'rgba(128, 41, 255, 0.1)' : 'transparent',
                  color: location.pathname === item.path ? '#8029ff' : '#ffffff'
                }}
              >
                {item.label}
              </Link>
            ))}
          </div>
        </div>
      );
    }
  
    return (
      <Nav.Link
        key={link.path}
        as={Link}
        to={link.path}
        className="nav-link-custom"
        onClick={handleNavLinkClick}
        style={{
          fontWeight: location.pathname === link.path ? 'bold' : 'normal',
        }}
      >
        {link.label}
      </Nav.Link>
    );
  };

  return (
    <Navbar 
      expand="lg" 
      className={`navbar ${isAnimating ? 'animating' : ''}`}
      expanded={isOpen}
    >
      <Container>
        <Navbar.Brand as={Link} to="/home" onClick={scrollToTop}>
          <Image src={logoSrc} alt="Pomu Logo" fluid />
        </Navbar.Brand>
        
        <Navbar.Toggle 
          aria-controls="basic-navbar-nav" 
          onClick={handleMenuToggle}
          className={isOpen ? 'active' : ''}
        />
        
        <Navbar.Collapse id="basic-navbar-nav" className={isAnimating ? 'animating' : ''}>
          <Nav className="me-auto">
            {filteredNavLinks.map(renderNavItem)}
          </Nav>

          <div className="auth-buttons">
            {isAuthenticated ? (
              <Dropdown align="end">
                <Dropdown.Toggle 
                  id="user-dropdown"
                  className="button-custom dropdown-toggle"
                >
                  <User size={20} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item 
                    as={Link} 
                    to="/profile" 
                    className="dropdown-item-custom"
                    onClick={handleNavLinkClick}
                  >
                    <User size={16} className="me-2" />
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item 
                    onClick={handleLogoutAndRedirect}
                    className="dropdown-item-custom"
                  >
                    <LogOut size={16} className="me-2" />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <Button 
                  variant="outline-light" 
                  onClick={handleLogin} 
                  className="button-custom"
                  size="sm"
                >
                  Login
                </Button>
                <Button 
                  as={Link} 
                  to="/AuthPage" 
                  variant="light"
                  className="button-custom"
                  size="sm"
                  onClick={handleNavLinkClick}
                >
                  Sign Up
                </Button>
              </>
            )}
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default GlobalNavbar;