import React, { useState, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Container, Button, Image, OverlayTrigger, Tooltip, Badge, Card, Row, Col } from 'react-bootstrap';
import { Code, Info, ChevronRight, ArrowLeft } from 'lucide-react';
import { AppContext } from '../../AppContext';
import { format } from 'date-fns';
import './ManuResults.css';

const userFriendlyTitles = {
  'image_vs_image': 'Visual Match',
  'image_vs_filename': 'Name Match',
  'text_vs_image': 'Keyword Match',
  'text_vs_filename': 'Description-Name Match',
  'combined_similarity': 'Overall Match'
};

const ManufacturerResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { results, userSubmittedImage, userSubmittedPrompt, isDark } = useContext(AppContext);
  const [isDeveloperView, setIsDeveloperView] = useState(false);
  
  const isNewSubmission = location.state?.from === 'sketch' && location.state?.submissionUpdated;

  const toggleView = () => setIsDeveloperView(!isDeveloperView);

  const handleBack = () => {
    const fromHistory = location.state?.from === 'history';
    const fromSketch = location.state?.from === 'sketch';
    const fromDuplicateDialog = location.state?.from === 'duplicate';

    if (fromHistory) {
        navigate('/history');
    } else if (fromSketch) {
        navigate('/sketch');
    } else if (fromDuplicateDialog) {
        navigate('/sketch', {
            state: {
                fromDuplicateDialog: true
            }
        });
    } else {
        navigate(-1);
    }
  };

  const handleManufacturerSelect = (result) => {
    navigate(`/manufacturer/${encodeURIComponent(result.manufacturer_name)}`, {
      state: {
        manufacturerName: result.manufacturer_name,
        from: 'results',
        originalSource: location.state?.from || 'sketch',
        submissionUpdated: location.state?.submissionUpdated
      }
    });
  };

  const renderInfoCard = () => (
    <Card className={`mt-4 ${isDark ? 'bg-dark text-light' : 'bg-light'} shadow-sm border-0`}>
      <Card.Body>
        <div className="d-flex align-items-center mb-3">
          <Info size={24} className="me-2 text-primary" />
          <h3 className="mb-0 fw-bold">Understanding Your Results</h3>
        </div>
        <p className="mb-0">
          Select a manufacturer to view more details and connect with them.
        </p>
        {isNewSubmission && (
          <p className="mt-3 mb-0">
            View all your past submissions{' '}
            <Link 
              to="/history" 
              className={`fw-bold ${isDark ? 'text-light' : 'text-primary'}`}
            >
              here
            </Link>.
          </p>
        )}
      </Card.Body>
    </Card>
  );

  if (!results || !userSubmittedImage) {
    return (
      <Container className="py-5">
        <h1 className={`mb-4 ${isDark ? 'text-light' : ''} display-4 fw-bold`}>Manufacturer Results</h1>
        <Card className={`p-5 text-center ${isDark ? 'bg-dark text-light' : 'bg-light'} border-0 shadow`}>
          <Card.Body>
            <p className="mb-4 fs-5">No results available. Please submit a tech pack first.</p>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  const renderResultItem = (key, value) => (
    <div 
      key={key} 
      className={`mb-3 result-card ${isDark ? 'bg-dark text-light' : 'bg-white'} shadow-sm border-0`}
      onClick={() => handleManufacturerSelect(value)}
    >
      <div className="result-inner">
        <div className="result-image-container">
          <Image
            src={`data:image/jpeg;base64,${value.base64_encoding}`}
            alt={`${value.manufacturer_name} sample`}
            className="result-image"
          />
        </div>
        <div className="result-content">
          <div className="result-header">
            <h4 className="mb-2 fw-bold">{value.manufacturer_name}</h4>
            <ChevronRight size={24} className={`text-${isDark ? 'light' : 'dark'}`} />
          </div>
          <Badge bg={isDark ? "light" : "primary"} text={isDark ? "dark" : "light"} className="mb-2">
            {userFriendlyTitles[key] || key.replace(/_/g, ' ').toUpperCase()}
          </Badge>
          {isDeveloperView && (
            <div className="mt-2 small">
              <p className="mb-1">
                <strong>Similarity Score:</strong> {value.similarity.toFixed(4)}
              </p>
              <p className="mb-0 text-muted">Match type: {key}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Container className="position-relative page-container">
      <div className="d-flex flex-column mb-4">
        <Button
          variant="link"
          onClick={handleBack}
          className={`back-button p-0 mb-3 align-self-start ${isDark ? 'text-light' : 'text-dark'}`}
          style={{ textDecoration: 'none' }}
        >
          <div className="d-flex align-items-center">
            <ArrowLeft size={24} className="me-2" />
            <span className="fw-medium">
              Back to {
                location.state?.from === 'history' ? 'History' : 
                location.state?.from === 'duplicate' ? 'Upload' :
                'Sketch'
              }
            </span>
          </div>
        </Button>

        <div className="d-flex justify-content-between align-items-center">
          <h1 className={`mb-0 ${isDark ? 'text-light' : ''} main-header`}>Manufacturer Results</h1>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="developer-view-tooltip">Toggle Developer View</Tooltip>}
          >
            <Button
              variant="link"
              onClick={toggleView}
              className={`p-0 ${isDark ? 'text-light' : 'text-dark'}`}
              style={{ opacity: 0.7 }}
            >
              <Code size={24} />
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      
      <div className={`mb-4 user-submission ${isDark ? 'bg-dark text-light' : 'bg-white'} shadow-sm border-custom-blue`}>
        <div className="result-inner">
          <div className="result-image-container">
            <Image
              src={`data:image/jpeg;base64,${userSubmittedImage}`}
              alt="Your submitted design"
              className="result-image"
            />
          </div>
          <div className="result-content">
            <h4 className="mb-2 sub-header">Your Design</h4>
            {userSubmittedPrompt && (
              <p className="mb-3 description-text">
                <strong>Your Description:</strong> {userSubmittedPrompt}
              </p>
            )}
            {location.state?.from === 'history' && location.state?.submissionDate && (
              <small className={`${isDark ? 'text-light-50' : 'text-muted'}`}>
                {format(new Date(location.state.submissionDate), 'MMM d, yyyy')}
              </small>
            )}
          </div>
        </div>
      </div>
      
      <div className="results-container">
        {Object.entries(results).map(([key, value]) => renderResultItem(key, value))}
      </div>
      
      {renderInfoCard()}
    </Container>
  );
};

export default ManufacturerResults;