import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { motion } from "framer-motion";
import {
  Share1,
  Heart1,
  Images,
  ChevronLeftIcon,
  ExternalLink,
  X,
  ChevronLeft,
  ChevronRight,
  Building,
} from "lucide-react";
import useAPIService from "../APIService";
import "./ManuTemplate.css";

const ImageCarousel = ({ images, onImageClick }) => {
  const [startIndex, setStartIndex] = useState(0);
  const imagesPerView = 4;

  const handleNext = () => {
    if (startIndex + imagesPerView < images.length) {
      setStartIndex((prev) => prev + 1);
    }
  };

  const handlePrev = () => {
    if (startIndex > 0) {
      setStartIndex((prev) => prev - 1);
    }
  };

  return (
    <div className="relative w-full">
      <div className="flex items-center">
        <button
          onClick={handlePrev}
          className={`absolute left-0 z-10 p-2 rounded-full bg-white shadow-lg mobile_border_size ${
            startIndex === 0
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-gray-100"
          }`}
          disabled={startIndex === 0}
        >
          <ChevronLeft size={20} />
        </button>

        <div className="flex gap-sm-4 overflow-hidden mx-12 mobile_spacing">
          {images
            .slice(startIndex, startIndex + imagesPerView)
            .map((item, index) => (
              <div
                key={startIndex + index}
                className="w-24 h-24 flex-shrink-0 cursor-pointer rounded-lg transition-transform duration-200 hover:scale-105 mobile_image"
                onClick={() => onImageClick(item.image)}
              >
                <img
                  src={`data:image/jpeg;base64,${item.image}`}
                  alt={`Portfolio ${startIndex + index + 1}`}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ))}
        </div>

        <button
          onClick={handleNext}
          className={`absolute right-0 z-10 p-2 rounded-full bg-white shadow-lg mobile_border_size ${
            startIndex + imagesPerView >= images.length
              ? "opacity-50 cursor-not-allowed"
              : "hover:bg-gray-100"
          }`}
          disabled={startIndex + imagesPerView >= images.length}
        >
          <ChevronRight size={24} />
        </button>
      </div>
    </div>
  );
};

const ImageOverlay = ({ image, onClose }) => {
  if (!image) return null;

  return (
    <div className="image-overlay">
      <div className="image-overlay-content">
        <button onClick={onClose} className="close-button">
          <X size={24} />
        </button>
        <img src={`data:image/jpeg;base64,${image}`} alt="Enlarged view" />
      </div>
    </div>
  );
};

const ManufacturerTemplate = () => {
  const { manufacturerId, fabricId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [itemData, setItemData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const apiService = useAPIService();
  const fetchingRef = useRef(false);

  const isFabric = location.pathname.includes("/fabric/");
  const itemId = manufacturerId || fabricId;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (fetchingRef.current || !itemId) return;
      fetchingRef.current = true;

      try {
        setIsLoading(true);

        const data = isFabric
          ? await apiService.GetFabricDetails(itemId)
          : await apiService.GetManufacturerDetails(itemId, { imageLimit: 15 });

        setItemData(data);
        setError(null);
      } catch (err) {
        console.error(`Error fetching data:`, err);
        if (location.state?.itemData) {
          setItemData(location.state.itemData);
          setError(null);
        } else {
          setError(
            `Failed to fetch ${
              isFabric ? "fabric" : "manufacturer"
            } data. Please try again later.`
          );
        }
      } finally {
        setIsLoading(false);
        fetchingRef.current = false;
      }
    };

    fetchData();
    return () => {
      fetchingRef.current = false;
    };
  }, [itemId, apiService, isFabric, location.state]);

  const handleBack = () => {
    if (location.state?.from === "results") {
      // Preserve existing results page navigation
      navigate(`/${isFabric ? "fabric" : "manufacturer"}-results`, {
        state: {
          from: location.state?.originalSource,
          submissionUpdated: location.state?.submissionUpdated,
        },
      });
    } else if (location.state?.from === "list") {
      // Navigate back to list view with preserved state
      navigate(`/${isFabric ? "fabrics" : "manufacturers"}`, {
        state: {
          previousPage: location.state?.previousPage,
          previousSearch: location.state?.previousSearch,
          previousMode: location.state?.previousMode,
        },
      });
    } else {
      // Default navigation if no state is present
      navigate(`/${isFabric ? "fabrics" : "manufacturers"}`);
    }
  };

  const handleWebsiteClick = (url) => {
    if (!url) return;
    const properUrl = url.startsWith("http") ? url : `https://${url}`;
    window.open(properUrl, "_blank", "noopener noreferrer");
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const portfolio = itemData
    ? isFabric
      ? itemData.base64_image
        ? [{ image: itemData.base64_image }]
        : []
      : itemData.portfolio || []
    : [];

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <div className="w-full h-16 bg-gray-200 animate-pulse mb-4"></div>
        <div className="flex flex-col gap-6 items-center w-11/12 max-w-screen-lg">
          <div className="w-full h-48 bg-gray-200 animate-pulse"></div>
          <div className="w-full h-24 bg-gray-200 animate-pulse"></div>
          <div className="w-full h-16 bg-gray-200 animate-pulse"></div>
        </div>
        <div className="w-full h-16 bg-gray-200 animate-pulse mt-auto"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6">
        <div className="text-red-600 p-4 rounded-lg bg-red-50">{error}</div>
      </div>
    );
  }

  return (
    <div className="desktop-manufacturer">
      <ImageOverlay
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <div className="div-2">
        <div className="mb-[-24px]">
          <div className="back-button mb-1" onClick={handleBack}>
            <ChevronLeftIcon size={20} />
            <span>Back</span>
          </div>
          <h1 className="text-wrapper-2">{itemData?.name || "Loading..."}</h1>
        </div>

        <div className="image-grid">
          {portfolio[0] ? (
            <div
              className="image cursor-pointer"
              style={{
                backgroundImage: `url(data:image/jpeg;base64,${
                  portfolio[0]?.image || ""
                })`,
              }}
              onClick={() => handleImageClick(portfolio[0]?.image)}
            />
          ) : (
            <div className="image flex items-center justify-center bg-gray-100">
              <Building size={48} className="text-gray-400" />
            </div>
          )}
        </div>

        <div className="frame">
          <div>
            {!isFabric && portfolio.length > 5 && (
              <div className="frame-5">
                <ImageCarousel
                  images={portfolio.slice(5)}
                  onImageClick={handleImageClick}
                />
              </div>
            )}

            <div className="frame-5">
              <h2 className="text-wrapper-5">
                {isFabric ? "Fabric Details" : "Manufacturer Details"}
              </h2>
              <p className="location-text">
                {isFabric
                  ? `${itemData?.supplier?.name || "Unknown Supplier"} - ${
                      itemData?.supplier?.location || "Location not available"
                    }`
                  : itemData?.location}
              </p>
              <p className="p">
                {isFabric
                  ? `${itemData?.composition || "Composition not specified"}
                     ${
                       itemData?.production_info
                         ? `\nProduction Info: ${itemData?.production_info}`
                         : ""
                     }`
                  : itemData?.description}
              </p>
            </div>

            <div className="frame-5">
              <h2 className="text-wrapper-5">
                {isFabric ? "Specifications" : "Specialties"}
              </h2>
              <div className="frame-6">
                {isFabric ? (
                  <>
                    {itemData?.specifications?.width && (
                      <div className="badge">
                        Width: {itemData.specifications.width}
                      </div>
                    )}
                    {itemData?.specifications?.weight && (
                      <div className="badge">
                        Weight: {itemData.specifications.weight}
                      </div>
                    )}
                    {itemData?.color && (
                      <div className="badge">Color: {itemData.color}</div>
                    )}
                    {itemData?.certificate && (
                      <div className="badge">
                        Certificate: {itemData.certificate}
                      </div>
                    )}
                  </>
                ) : (
                  itemData?.specialties?.map((specialty, index) => (
                    <div key={index} className="badge">
                      {specialty}
                    </div>
                  ))
                )}
              </div>
            </div>

            {isFabric && (
              <div className="frame-5">
                <h2 className="text-wrapper-5">Pricing</h2>
                <div className="frame-6">
                  {itemData?.pricing?.price && (
                    <div className="badge">
                      Price: ${itemData.pricing.price}/unit
                    </div>
                  )}
                  {itemData?.pricing?.moq && (
                    <div className="badge">MOQ: {itemData.pricing.moq}</div>
                  )}
                  {itemData?.pricing?.lead_time && (
                    <div className="badge">
                      Lead Time: {itemData.pricing.lead_time}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="frame-7">
            <h2 className="text-wrapper-5">Contact us</h2>
            <motion.button
              className="website-button"
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.98 }}
              onClick={() =>
                handleWebsiteClick(itemData?.url || itemData?.website)
              }
              disabled={!itemData?.url && !itemData?.website}
            >
              <span>
                {itemData?.url || itemData?.website || "Website not available"}
              </span>
              {(itemData?.url || itemData?.website) && (
                <ExternalLink size={16} />
              )}
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManufacturerTemplate;
