import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { Search, Loader } from "lucide-react";
import debounce from "lodash/debounce";
import "./ManuSearch.css";
import { cacheService } from "../../cacheService";

const FILTER_TYPES = {
  LOCATION: "location",
  SPECIALTY: "specialty",
  COMPOSITION: "composition",
};

const LoadingOverlay = () => (
  <div className="absolute inset-0 bg-black/5 backdrop-blur-[2px] flex items-center justify-center">
    <div className="flex items-center gap-2 bg-white/90 px-3 py-2 rounded-full shadow-sm">
      <Loader className="animate-spin" size={16} />
      <span className="text-sm">Loading...</span>
    </div>
  </div>
);

const ManufacturerSearch = ({
  isDark,
  onSearch,
  initialMode = "manufacturers",
  initialLocationQuery = "",
  initialTypeQuery = "",
  isLoading = false,
}) => {
  const [locationQuery, setLocationQuery] = useState(initialLocationQuery);
  const [typeQuery, setTypeQuery] = useState(initialTypeQuery);
  const [searchMode, setSearchMode] = useState(initialMode);

  useEffect(() => {
    setLocationQuery(initialLocationQuery);
    setTypeQuery(initialTypeQuery);
    setSearchMode(initialMode);
  }, [initialLocationQuery, initialTypeQuery, initialMode]);

  const handleSearchSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (onSearch) {
        onSearch({
          location: locationQuery.trim(),
          type: typeQuery.trim(),
          mode: searchMode,
        });
      }
    },
    [onSearch, locationQuery, typeQuery, searchMode]
  );

  const debouncedOnSearch = useMemo(
    () =>
      debounce((location, type, mode) => {
        if (onSearch) {
          onSearch({
            location: location.trim(),
            type: type.trim(),
            mode,
          });
        }
      }, 150),
    [onSearch]
  );

  const handleLocationChange = (e) => {
    const newLocation = e.target.value;
    setLocationQuery(newLocation);
    debouncedOnSearch(newLocation, typeQuery, searchMode);
  };

  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setTypeQuery(newType);
    debouncedOnSearch(locationQuery, newType, searchMode);
  };

  const handleModeSwitch = (newMode) => {
    if (isLoading || newMode === searchMode) return;
    setSearchMode(newMode);
    setLocationQuery("");
    setTypeQuery("");
    cacheService.invalidateListCache();
    cacheService.invalidateListPageState();
    debouncedOnSearch("", "", newMode);
  };

  const getPlaceholders = () => {
    if (searchMode === "manufacturers") {
      return {
        location: "Pick a location",
        type: "Add clothing type",
      };
    }
    return {
      location: "Pick a location",
      type: "Search by composition",
    };
  };

  const placeholders = getPlaceholders();

  return (
    <div className="manufacturer-search-container">
      <div className="mx-auto" style={{ maxWidth: "600px" }}>
        {/* Mode Tabs */}
        <div className="manufacturer-tabs">
          <button
            className={`manufacturer-tab ${searchMode === "manufacturers" ? "active" : ""}`}
            onClick={() => handleModeSwitch("manufacturers")}
            disabled={isLoading}
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
          >
            Manufacturers
          </button>
          <button
            className={`manufacturer-tab ${searchMode === "fabrics" ? "active" : ""}`}
            onClick={() => handleModeSwitch("fabrics")}
            disabled={isLoading}
            style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
          >
            Textile
          </button>
        </div>

        <div
          className={`d-flex align-items-center justify-content-between shadow-sm rounded-pill p-2 position-relative ${
            isDark ? "bg-dark text-light" : "bg-light text-dark"
          }`}
          style={{ overflow: "hidden" }}
        >
          {/* Location/Supplier Field */}
          <div className="flex-grow-1 ms-3">
            <Form.Control
              type="text"
              placeholder={placeholders.location}
              value={locationQuery}
              onChange={handleLocationChange}
              disabled={isLoading}
              className={`border-0 mobile_input_text ${
                isDark ? "bg-dark text-light" : "bg-light text-dark"
              }`}
              style={{
                fontSize: "1rem",
                borderRadius: 0,
                boxShadow: "none",
                outline: "none",
              }}
            />
          </div>

          {/* Divider */}
          <div
            className="border-start mx-2"
            style={{ height: "1.5rem", opacity: 0.3 }}
          ></div>

          {/* Type/Composition Field */}
          <div className="flex-grow-1">
            <Form.Control
              type="text"
              placeholder={placeholders.type}
              value={typeQuery}
              onChange={handleTypeChange}
              disabled={isLoading}
              className={`border-0 mobile_input_text ${
                isDark ? "bg-dark text-light" : "bg-light text-dark"
              }`}
              style={{
                fontSize: "1rem",
                borderRadius: 0,
                boxShadow: "none",
                outline: "none",
              }}
            />
          </div>

          {/* Search Button */}
          <div className="ms-3 me-2">
            <Form onSubmit={handleSearchSubmit}>
              <Button
                type="submit"
                variant="link"
                className="p-0 border-0"
                style={{ lineHeight: 0 }}
                disabled={isLoading}
              >
                <Search
                  size={20}
                  className="opacity-75 hover:opacity-100 transition-opacity"
                />
              </Button>
            </Form>
          </div>

          {/* Loading Overlay */}
          {isLoading && <LoadingOverlay />}
        </div>
      </div>
    </div>
  );
};

export default ManufacturerSearch;