import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import image from "../../../images/about/join.svg";
import styles from "./styles.module.scss";

const Join = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleJoinTeam = () => {
    scrollToTop();
    navigate('/careers');
  };

  return (
    <div className={styles._join}>
      <Container>
        <div className={styles._join_wrapper}>
          <Row>
            <Col sm={6}>
              <div className={styles._join_content}>
                <h1 className={styles._join_heading}>Join our team</h1>
                <p className={styles._join_info}>
                  Apply to join our vibrant community. Get support, collaborate,
                  and make an impact—start your journey today!
                </p>
                <button 
                  className={styles._join_button}
                  onClick={handleJoinTeam}
                >
                  Join Our Team
                </button>
              </div>
            </Col>
            <Col sm={6}>
              <div className={styles._join_right}>
                <img src={image} alt="join" className={styles._join_image} />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Join;