// cacheService.js
const CACHE_KEYS = {
  MANUFACTURER_LIST: 'manufacturerListCache',
  SEARCH_SUGGESTIONS: 'searchSuggestionsCache',
  SUBMISSIONS_HISTORY: 'submissionHistoryCache',
  LAST_CLEANED: 'lastCacheCleanup',
  LIST_PAGE_STATE: 'listPageStateCache',
  POSITIONS_LIST: 'positionsListCache',
  POSITION_DETAILS: 'positionDetailsCache'
};

const CACHE_DURATION = {
  LIST: 5 * 60 * 1000, // 5 minutes for list data
  SUGGESTIONS: 3 * 60 * 1000, // 3 minutes for search suggestions
  SUBMISSIONS: 5 * 60 * 1000, // 5 minutes for submissions
  CLEANUP_INTERVAL: 10 * 60 * 1000, // Clean up every 10 minutes
  PAGE_STATE: 5 * 60 * 1000, // 5 minutes for page state
  POSITIONS: 5 * 60 * 1000, // 5 minutes for positions list
  POSITION_DETAILS: 10 * 60 * 1000 // 10 minutes for position details
};

class CacheService {
  constructor() {
    this.initializeCache();
  }

  initializeCache() {
    const lastCleanup = localStorage.getItem(CACHE_KEYS.LAST_CLEANED);
    if (!lastCleanup || Date.now() - parseInt(lastCleanup) > CACHE_DURATION.CLEANUP_INTERVAL) {
      this.cleanupCache();
    }
  }

  cleanupCache() {
    const now = Date.now();
    
    // Clean list cache
    const listCache = this.getCache(CACHE_KEYS.MANUFACTURER_LIST) || {};
    Object.keys(listCache).forEach(key => {
      if (now - listCache[key].timestamp > CACHE_DURATION.LIST) {
        delete listCache[key];
      }
    });
    this.setCache(CACHE_KEYS.MANUFACTURER_LIST, listCache);

    // Clean suggestions cache
    const suggestionsCache = this.getCache(CACHE_KEYS.SEARCH_SUGGESTIONS) || {};
    Object.keys(suggestionsCache).forEach(key => {
      if (now - suggestionsCache[key].timestamp > CACHE_DURATION.SUGGESTIONS) {
        delete suggestionsCache[key];
      }
    });
    this.setCache(CACHE_KEYS.SEARCH_SUGGESTIONS, suggestionsCache);

    // Clean submissions cache
    const submissionsCache = this.getCache(CACHE_KEYS.SUBMISSIONS_HISTORY);
    if (submissionsCache && (now - submissionsCache.timestamp > CACHE_DURATION.SUBMISSIONS)) {
      localStorage.removeItem(CACHE_KEYS.SUBMISSIONS_HISTORY);
    }

    // Clean page state cache
    const pageStateCache = this.getCache(CACHE_KEYS.LIST_PAGE_STATE);
    if (pageStateCache && (now - pageStateCache.timestamp > CACHE_DURATION.PAGE_STATE)) {
      localStorage.removeItem(CACHE_KEYS.LIST_PAGE_STATE);
    }

    // Clean positions cache
    const positionsCache = this.getCache(CACHE_KEYS.POSITIONS_LIST);
    if (positionsCache && (now - positionsCache.timestamp > CACHE_DURATION.POSITIONS)) {
      localStorage.removeItem(CACHE_KEYS.POSITIONS_LIST);
    }

    // Clean position details cache
    const positionDetailsCache = this.getCache(CACHE_KEYS.POSITION_DETAILS) || {};
    Object.keys(positionDetailsCache).forEach(key => {
      if (now - positionDetailsCache[key].timestamp > CACHE_DURATION.POSITION_DETAILS) {
        delete positionDetailsCache[key];
      }
    });
    this.setCache(CACHE_KEYS.POSITION_DETAILS, positionDetailsCache);

    localStorage.setItem(CACHE_KEYS.LAST_CLEANED, now.toString());
  }

  getCache(key) {
    try {
      const cached = localStorage.getItem(key);
      return cached ? JSON.parse(cached) : null;
    } catch (error) {
      console.error('Error getting cache:', error);
      return null;
    }
  }

  setCache(key, data) {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error('Error setting cache:', error);
      this.clearCache(); // Clear cache if we hit storage limits
    }
  }

  clearCache() {
    Object.values(CACHE_KEYS).forEach(key => {
      localStorage.removeItem(key);
    });
  }

  // List page state methods
  getListPageState() {
    const cached = this.getCache(CACHE_KEYS.LIST_PAGE_STATE);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION.PAGE_STATE) {
      return cached.data;
    }
    return null;
  }

  setListPageState(stateData) {
    this.setCache(CACHE_KEYS.LIST_PAGE_STATE, {
      data: stateData,
      timestamp: Date.now()
    });
  }

  // List data methods
  getListData(page, searchQuery) {
    const listCache = this.getCache(CACHE_KEYS.MANUFACTURER_LIST) || {};
    const cacheKey = `${page}-${searchQuery || ''}`;
    const cached = listCache[cacheKey];

    if (cached && Date.now() - cached.timestamp < CACHE_DURATION.LIST) {
      return cached.data;
    }
    return null;
  }

  setListData(page, searchQuery, data) {
    const listCache = this.getCache(CACHE_KEYS.MANUFACTURER_LIST) || {};
    const cacheKey = `${page}-${searchQuery || ''}`;
    
    listCache[cacheKey] = {
      data,
      timestamp: Date.now()
    };

    this.setCache(CACHE_KEYS.MANUFACTURER_LIST, listCache);
  }

  // Search suggestions methods
  getSuggestions(query, filterType) {
    const suggestionsCache = this.getCache(CACHE_KEYS.SEARCH_SUGGESTIONS) || {};
    const cacheKey = `${filterType}-${query.toLowerCase().trim()}`;
    const cached = suggestionsCache[cacheKey];

    if (cached && Date.now() - cached.timestamp < CACHE_DURATION.SUGGESTIONS) {
      return cached.data;
    }
    return null;
  }

  setSuggestions(query, filterType, data) {
    const suggestionsCache = this.getCache(CACHE_KEYS.SEARCH_SUGGESTIONS) || {};
    const cacheKey = `${filterType}-${query.toLowerCase().trim()}`;
    
    suggestionsCache[cacheKey] = {
      data,
      timestamp: Date.now()
    };

    this.setCache(CACHE_KEYS.SEARCH_SUGGESTIONS, suggestionsCache);
  }

  // Careers and positions methods
  getPositions() {
    const cached = this.getCache(CACHE_KEYS.POSITIONS_LIST);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION.POSITIONS) {
      return cached.data;
    }
    return null;
  }

  setPositions(data) {
    this.setCache(CACHE_KEYS.POSITIONS_LIST, {
      data,
      timestamp: Date.now()
    });
  }

  getPositionDetails(positionId) {
    const positionsCache = this.getCache(CACHE_KEYS.POSITION_DETAILS) || {};
    const cached = positionsCache[positionId];
    
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION.POSITION_DETAILS) {
      return cached.data;
    }
    return null;
  }

  setPositionDetails(positionId, data) {
    const positionsCache = this.getCache(CACHE_KEYS.POSITION_DETAILS) || {};
    
    positionsCache[positionId] = {
      data,
      timestamp: Date.now()
    };

    this.setCache(CACHE_KEYS.POSITION_DETAILS, positionsCache);
  }

  // Submissions methods
  getSubmissions() {
    const cached = this.getCache(CACHE_KEYS.SUBMISSIONS_HISTORY);
    if (cached?.data && Date.now() - cached.timestamp < CACHE_DURATION.SUBMISSIONS) {
      return {
        data: cached.data,
        isValid: true
      };
    }
    return { isValid: false };
  }

  setSubmissions(data) {
    this.setCache(CACHE_KEYS.SUBMISSIONS_HISTORY, {
      data,
      timestamp: Date.now()
    });
  }

  // Cache invalidation methods
  invalidateListCache() {
    localStorage.removeItem(CACHE_KEYS.MANUFACTURER_LIST);
  }

  invalidateSearchCache() {
    localStorage.removeItem(CACHE_KEYS.SEARCH_SUGGESTIONS);
  }

  invalidateSubmissionsCache() {
    localStorage.removeItem(CACHE_KEYS.SUBMISSIONS_HISTORY);
  }

  invalidateListPageState() {
    localStorage.removeItem(CACHE_KEYS.LIST_PAGE_STATE);
  }

  invalidatePositionsCache() {
    localStorage.removeItem(CACHE_KEYS.POSITIONS_LIST);
    localStorage.removeItem(CACHE_KEYS.POSITION_DETAILS);
  }

  invalidateAllCaches() {
    this.invalidateListCache();
    this.invalidateSearchCache();
    this.invalidateSubmissionsCache();
    this.invalidateListPageState();
    this.invalidatePositionsCache();
  }

  invalidateAuthSensitiveCaches() {
    // Only invalidate caches that should be cleared on auth changes
    this.invalidateSubmissionsCache();
  }

  invalidateAllExceptPageState() {
    this.invalidateListCache();
    this.invalidateSearchCache();
    this.invalidateSubmissionsCache();
    this.invalidatePositionsCache();
    // Deliberately not invalidating page state
  }
}

export const cacheService = new CacheService();
export default cacheService;