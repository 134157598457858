import img1 from "../../../images/home/content-1.png";
import img2 from "../../../images/home/content-2.png";
import img3 from "../../../images/home/content-3.png";

export const contents = [
  {
    id: 1,
    title: "Upload",
    content:
      "Upload your design—sketch, image, or tech drawing—and Pomu will match it with the best manufacturers.",
    image: img1,
  },
  {
    id: 2,
    title: "Search",
    content:
      "Effortlessly find the perfect manufacturer for your designs. Use Pomu’s AI-powered search to connect with verified partners in just a few clicks",
    image: img2,
  },
  {
    id: 3,
    title: "Connect",
    content:
      "Connect directly with trusted manufacturers and start collaborating—no hassle, just results.",
    image: img3,
  },
];
