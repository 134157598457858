import React from "react";
import styles from "./styles.module.scss";
import image from "../../../images/home/mid-img.png";
import { Container } from "react-bootstrap";

const Mid = () => {
  return (
    <div className={`${styles._mid_wrapper}`}>
      <Container>
        <div className={styles._mid_content}>
          <div className={styles._mid_image}>
            <div>
              <img
                src={image}
                alt="Manufacturer search interface"
                className="img-fluid rounded-4 shadow"
              />
            </div>
          </div>
          <div className={styles._mid_text}>
            <div>
              <h2 className={styles._mid_heading}>
                No more sifting through unreliable leads
              </h2>
              <p className={styles._mid_paragraph}>
                Pomu makes it easy by connecting you with trusted, verified
                manufacturers in just a few clicks. Say goodbye to the hassle
                and let us simplify your search, so you can focus on what
                matters most—bringing your designs to life.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Mid;
