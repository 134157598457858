import axios from "axios";
import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";

const BASE_URL = "https://prodapi.pomu.io"; // "https://prodapi.pomu.io" "http://localhost:8000"

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const pendingRequests = new Map();

axiosInstance.interceptors.request.use(
  (config) => {
    const requestKey = `${config.method}-${config.url}-${JSON.stringify(
      config.params
    )}`;

    if (pendingRequests.has(requestKey)) {
      const controller = new AbortController();
      config.signal = controller.signal;
      controller.abort("Duplicate request cancelled");
    } else {
      pendingRequests.set(requestKey, true);
    }

    return {
      ...config,
      metadata: { requestKey },
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    const requestKey = response.config.metadata.requestKey;
    pendingRequests.delete(requestKey);
    return response;
  },
  (error) => {
    if (error.config) {
      const requestKey = error.config.metadata.requestKey;
      pendingRequests.delete(requestKey);
    }
    return Promise.reject(error);
  }
);

const useAPIService = () => {
  const { getAuthToken, userEmail } = useContext(AppContext);

  return useMemo(
    () => ({
      SubmitUserRequest: async (formData) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          };
          const response = await axiosInstance.post(
            "/api/submit-user-request/",
            formData,
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      SubmitTechPack: async (formData) => {
        try {
          const token = await getAuthToken();

          const image = formData.get("image");
          const prompt = formData.get("prompt");
          const userEmail = formData.get("userEmail");

          if (!image || !prompt || !userEmail) {
            throw new Error("Missing required submission data");
          }

          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          };

          const response = await axiosInstance.post(
            "/api/submit-tech-pack/",
            formData,
            config
          );

          if (!response.data) {
            throw new Error("Empty response from server");
          }

          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      SubmitContactForm: async (formData) => {
        try {
          const response = await axiosInstance.post(
            "/api/submit-contact-form/",
            formData
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetManufacturerList: async (
        page = 1,
        pageSize = 10,
        searchQuery = ""
      ) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
            params: {
              page,
              page_size: pageSize,
              search: searchQuery,
            },
          };
          const response = await axiosInstance.get(
            "/api/manufacturers/",
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetManufacturerDetails: async (manufacturerName, options = {}) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
            params: {
              image_limit: options?.imageLimit || 5,
            },
          };
          const response = await axiosInstance.get(
            `/api/manufacturer/${encodeURIComponent(manufacturerName)}/`,
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetManufacturerSuggestions: async (
        searchQuery,
        limit = 10,
        filterType = "name"
      ) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
            params: {
              search: searchQuery,
              limit: limit,
              filter_type: filterType,
            },
          };
          const response = await axiosInstance.get(
            "/api/manufacturer-suggestions/",
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      DeleteSubmission: async (submissionId) => {
        try {
          const token = await getAuthToken();

          if (!userEmail) {
            throw new Error("User email not found");
          }

          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userEmail: userEmail,
            },
          };

          const response = await axiosInstance.delete(
            `/api/submissions/${submissionId}/`,
            config
          );
          return response.data;
        } catch (error) {
          console.error("Delete submission error:", error);
          throw error;
        }
      },

      CheckDuplicateImage: async (formData) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          };

          const response = await axiosInstance.post(
            "/api/check-duplicate-image/",
            formData,
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetUserSubmissions: async () => {
        try {
          const token = await getAuthToken();

          if (!userEmail) {
            throw new Error("User email not found");
          }

          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              userEmail: userEmail,
            },
          };

          const response = await axiosInstance.get(
            "/api/user-submissions/",
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      SubmitManufacturerForm: async (formData) => {
        try {
          const token = await getAuthToken();

          // Validate required fields
          const requiredFields = [
            "name",
            "email",
            "phone",
            "address",
            "country",
            "garment_specializations",
            "fabric_specializations",
            "bio",
          ];

          for (const field of requiredFields) {
            if (!formData.get(field)) {
              throw new Error(`Missing required field: ${field}`);
            }
          }

          // Validate images
          const images = formData.get("images");
          if (!images) {
            throw new Error("Product images are required");
          }

          if (images.type !== "application/zip") {
            throw new Error("Images must be uploaded as a ZIP file");
          }

          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
              ...(token && { Authorization: `Bearer ${token}` }),
            },
          };

          const response = await axiosInstance.post(
            "/api/submit-manufacturer/",
            formData,
            config
          );

          if (!response.data) {
            throw new Error("Empty response from server");
          }

          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetFabricList: async (page = 1, pageSize = 10, searchQuery = "") => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
            params: {
              page,
              page_size: pageSize,
              search: searchQuery,
            },
          };
          const response = await axiosInstance.get("/api/fabrics/", config);
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetFabricDetails: async (fabricName) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
          };
          const response = await axiosInstance.get(
            `/api/fabrics/${encodeURIComponent(fabricName)}/`,
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetPositions: async () => {
        try {
          const response = await axiosInstance.get("/api/positions/");
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },
      
      GetPositionDetails: async (positionId) => {
        try {
          const response = await axiosInstance.get(`/api/positions/${positionId}/`);
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },
      
      SubmitApplication: async (formData) => {
        try {
          const response = await axiosInstance.post(
            "/api/submit-application/",
            formData
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },

      GetFabricSuggestions: async (
        searchQuery,
        limit = 10,
        filterType = "name"
      ) => {
        try {
          const token = await getAuthToken();
          const config = {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
            params: {
              search: searchQuery,
              limit: limit,
              filter_type: filterType,
            },
          };
          const response = await axiosInstance.get(
            "/api/fabric-suggestions/",
            config
          );
          return response.data;
        } catch (error) {
          if (axios.isCancel(error)) {
            return null;
          }
          throw error;
        }
      },
    }),
    [getAuthToken, userEmail]
  );
};

export default useAPIService;
