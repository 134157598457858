import React from "react";
import styles from "./styles.module.scss";

const SignIn = () => {
  return (
    <div className={styles._signIn}>
      <div className={styles._signIn_tabs}>
        <button className={styles._signIn_button}>Sign In</button>
        <button className={styles._signUp_button}>Sign Up</button>
      </div>

      <h1>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse molestiae
        cumque praesentium voluptatem animi voluptas tempora magni ab autem quia
        cupiditate nostrum modi, alias, nisi quos placeat suscipit hic quidem.
      </h1>
    </div>
  );
};

export default SignIn;
