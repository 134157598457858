import React, { useState } from 'react';
import { 
  Check, 
  Upload, 
  ChevronRight, 
  ChevronLeft, 
  RefreshCw, 
  Globe,
  BarChart,
  Zap,
  MapPin,
  Settings,
  MessageSquare,
  Smartphone,
  TrendingUp,
  Lightbulb,
  Handshake,
  ArrowLeft
} from 'lucide-react';
import useAPIService from './APIService';
import './styles/ManuContact.css';

const ManuContact = () => {
  const apiService = useAPIService();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    phone: '',
    address: '',
    country: '',
    garment_specializations: '',
    fabric_specializations: '',
    bio: '',
    images: null
  });
  const [previewFiles, setPreviewFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const validateStep = (currentStep) => {
    setError(null);
    switch (currentStep) {
      case 0:
        if (!formData.email) {
          setError('Please enter your email');
          return false;
        }
        if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          setError('Please enter a valid email address');
          return false;
        }
        break;
      case 1:
        if (!formData.name || !formData.phone || !formData.address || !formData.country) {
          setError('Please fill in all required fields');
          return false;
        }
        if (!formData.phone.match(/^[0-9+\-\s()]{8,}$/)) {
          setError('Please enter a valid phone number');
          return false;
        }
        break;
      case 2:
        if (!formData.garment_specializations || !formData.fabric_specializations || !formData.bio) {
          setError('Please provide all required information');
          return false;
        }
        break;
      case 3:
        if (!formData.images) {
          setError('Please upload your product images');
          return false;
        }
        break;
    }
    return true;
  };

  const handleNext = () => {
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
    setError(null);
  };

  const resetForm = () => {
    setStep(0);
    setFormData({
      email: '',
      name: '',
      phone: '',
      address: '',
      country: '',
      garment_specializations: '',
      fabric_specializations: '',
      bio: '',
      images: null
    });
    setPreviewFiles([]);
    setError(null);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (validateStep(0)) {
      setStep(1);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setError(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === 'application/zip') {
        setFormData(prev => ({ ...prev, images: file }));
        setPreviewFiles([file.name]);
        setError(null);
      } else {
        setError('Please upload a ZIP file');
      }
    }
  };

  const handleSubmit = async () => {
    if (!validateStep(3)) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const submitData = new FormData();
      Object.keys(formData).forEach(key => {
        submitData.append(key, formData[key]);
      });

      const response = await apiService.SubmitManufacturerForm(submitData);
      
      if (response) {
        setStep(4);
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error:', error);
      let errorMessage = 'An error occurred during submission. Please try again.';
      
      if (error.response?.data) {
        const { error: serverError, code } = error.response.data;
        
        switch (code) {
          case 'NO_FILE':
            errorMessage = 'Please upload a ZIP file containing your product images.';
            break;
          case 'INVALID_FILE_TYPE':
            errorMessage = 'The uploaded file must be a ZIP file.';
            break;
          case 'NO_VALID_IMAGES':
            errorMessage = 'Your ZIP file does not contain any valid images. Please ensure it contains JPG or PNG images of your products.';
            break;
          case 'INVALID_ZIP_CONTENT':
            errorMessage = serverError || 'The ZIP file content is invalid. Please check the file and try again.';
            break;
          case 'DUPLICATE_NAME':
            errorMessage = 'A manufacturer with this name already exists. Please use a different name.';
            setStep(1);
            break;
          default:
            errorMessage = serverError || errorMessage;
        }
      }
      
      setError(errorMessage);
      
      if (['NO_FILE', 'INVALID_FILE_TYPE', 'NO_VALID_IMAGES', 'INVALID_ZIP_CONTENT'].includes(error.response?.data?.code)) {
        setFormData(prev => ({ ...prev, images: null }));
        setPreviewFiles([]);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderStepHeader = () => {
    if (step === 0) return null;
    
    return (
      <div className="step-header">
        <div className="step-progress">
          {[1, 2, 3].map(stepNumber => (
            <div 
              key={stepNumber}
              className={`step-dot ${stepNumber === step ? 'active' : ''} ${stepNumber < step ? 'completed' : ''}`}
            />
          ))}
        </div>
        <div className="step-label">Step {step} of 3</div>
      </div>
    );
  };

  const renderStepContent = () => {
    switch(step) {
      case 0:
        return (
          <>
            <h1 className="main-title">
              Join our<br />
              manufacturers<br />
            </h1>
            <p className="subtitle">
            Join our manufacturers network where industry leaders come together. Our growing community brings together dedicated manufacturers who value quality and innovation, from specialized workshops to established facilities.
            </p>
            
            <form onSubmit={handleEmailSubmit} className="email-form">
              {error && <div className="error-message">{error}</div>}
              <div className="email-input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Enter your email"
                  className="email-input"
                />
                <button type="submit" className="get-started-button">
                  Get Started
                </button>
              </div>
            </form>

            <div className="manufacturers-joined">
              <div className="profile-stack">
                <div className="profile-images">
                  <img
                    className="profile-pic"
                    alt="Profile"
                    src="https://c.animaapp.com/KtX0zQtI/img/ellipse-252.svg"
                  />
                  <img
                    className="profile-pic"
                    alt="Profile"
                    src="https://c.animaapp.com/KtX0zQtI/img/ellipse-248.svg"
                  />
                  <img
                    className="profile-pic"
                    alt="Profile"
                    src="https://c.animaapp.com/KtX0zQtI/img/ellipse-251.svg"
                  />
                  <img
                    className="profile-pic"
                    alt="Profile"
                    src="https://c.animaapp.com/KtX0zQtI/img/ellipse-250.svg"
                  />
                  <div className="profile-count">2m</div>
                </div>
              </div>
              <span className="joined-text">Manufacturers Joined</span>
            </div>
          </>
        );

      case 1:
        return (
          <>
            <h1 className="main-title">
              Tell us about<br />
              your company
            </h1>
            <div className="form-step">
              {error && <div className="error-message">{error}</div>}
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="form-input"
                placeholder="Company Name *"
                required
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                className="form-input"
                placeholder="Business Phone *"
                required
              />
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                className="form-input"
                placeholder="Business Address *"
                required
              />
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className="form-input"
                placeholder="Country *"
                required
              />
            </div>
          </>
        );
      
      case 2:
        return (
          <>
            <h1 className="main-title">
              Share your<br />
              expertise
            </h1>
            <div className="form-step">
              {error && <div className="error-message">{error}</div>}
              <textarea
                name="bio"
                value={formData.bio}
                onChange={handleInputChange}
                className="form-textarea"
                placeholder="Tell us about your company, history, and what makes you unique "
                required
              />
              <textarea
                name="garment_specializations"
                value={formData.garment_specializations}
                onChange={handleInputChange}
                className="form-textarea"
                placeholder="List all garment types you manufacture (e.g., dresses, shirts, pants, etc.) "
                required
              />
              <textarea
                name="fabric_specializations"
                value={formData.fabric_specializations}
                onChange={handleInputChange}
                className="form-textarea"
                placeholder="List all fabric types you work with (e.g., cotton, silk, denim, etc.) "
                required
              />
            </div>
          </>
        );
      
      case 3:
        return (
          <>
            <h1 className="main-title">
              Showcase your<br />
              work
            </h1>
            <div className="form-step">
              {error && <div className="error-message">{error}</div>}
              <div className="info-alert">
                <p>Please provide photos of your manufactured clothing in a ZIP file. Each photo should be named according to what the garment is (e.g., "summer-dress.jpg").</p>
              </div>
              <div className="file-upload-box">
                <input
                  type="file"
                  accept=".zip"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="zip-upload"
                />
                <label htmlFor="zip-upload" className="upload-label">
                  <Upload size={24} className="mb-2" />
                  <p>Click to upload ZIP file containing your garment photos</p>
                  {previewFiles.length > 0 && (
                    <p className="selected-file">Selected: {previewFiles[0]}</p>
                  )}
                </label>
              </div>
            </div>
          </>
        );
      
      case 4:
        return (
          <>
            <h1 className="main-title">
              Welcome to<br />
              the community
            </h1>
            <div className="form-step">
              <div className="success-content">
                <div className="success-icon">
                  <Check size={32} />
                </div>
                <h3>Submission Successful!</h3>
                <p>Thank you for submitting. We'll review your submission and get back to you at {formData.email}</p>
                <button 
                  className="button button-secondary"
                  onClick={resetForm}
                >
                  <RefreshCw size={20} />
                  Submit Another
                </button>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const featureIcons = [
    { icon: Globe, label: 'Global Reach' },
    { icon: BarChart, label: 'Analytics' },
    { icon: Zap, label: 'Fast Processing' },
    { icon: MapPin, label: 'Location Based' },
    { icon: Settings, label: 'Customizable' },
    { icon: MessageSquare, label: 'Communication' },
    { icon: Smartphone, label: 'Mobile Ready' },
    { icon: TrendingUp, label: 'Growth' },
    { icon: Lightbulb, label: 'Innovation' },
    { icon: Handshake, label: 'Partnerships' }
  ];

  return (
    <div className="desktop-for">
      <div className="container">
        <div className="landing-container">
          <div className="left-content">
            {renderStepHeader()}
            {renderStepContent()}
            
            {step > 0 && step < 4 && (
              <div className="navigation-buttons">
                <button 
                  className="button button-secondary"
                  onClick={handleBack}
                >
                  <ChevronLeft size={20} />
                  Back
                </button>
                <button
                  className="button button-primary"
                  onClick={step === 3 ? handleSubmit : handleNext}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Submitting...' : step === 3 ? 'Submit' : (
                    <>
                      Next
                      <ChevronRight size={20} />
                    </>
                  )}
                </button>
              </div>
            )}
          </div>

          <div className="right-content">
            <div className="feature-grid">
              {featureIcons.map((feature, index) => (
                <div key={index} className="feature-icon">
                  <feature.icon size={24} color="#828282" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManuContact;