import React, { useState, useEffect, useContext } from "react";
import { Plus } from "../../icons/Plus";
import RightSide from "../../images/auth/auth.png";
import "../styles/./AuthPage.css";

import {
  signIn,
  signUp,
  confirmSignUp,
  resendSignUpCode,
  signInWithRedirect,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { cacheService } from "../../cacheService";

export const AuthPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateAuthStatus } = useContext(AppContext);

  const [isSignUp, setIsSignUp] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Common fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  // Sign Up additional field
  const [name, setName] = useState("");

  // Password Recovery State
  const [isRecovering, setIsRecovering] = useState(false);
  const [recoveryStep, setRecoveryStep] = useState(1); // 1: send code, 2: reset password
  const [recoveryCode, setRecoveryCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmRecoveryPassword, setConfirmRecoveryPassword] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    if (location.state?.message) {
      setError(location.state.message);
    }

    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      if (
        payload.event === "signedIn" ||
        payload.event === "cognitoHostedUI" ||
        payload.event === "federatedSignIn"
      ) {
        handleSuccessfulLogin();
      }
    });

    checkUser();
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const checkUser = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        handleSuccessfulLogin();
      }
    } catch {
      // No current user
    }
  };

  const getUserEmail = async (user) => {
    let userEmail = null;
    if (user.signInDetails?.loginId) {
      userEmail = user.signInDetails.loginId;
    } else if (user.username && user.username.includes("@")) {
      userEmail = user.username;
    } else if (user.attributes?.email) {
      userEmail = user.attributes.email;
    }

    if (!userEmail) {
      try {
        const session = await fetchAuthSession();
        const claims = session.tokens?.idToken?.payload;
        if (claims?.email) {
          userEmail = claims.email;
        }
      } catch (error) {
        console.error("Error getting email from session:", error);
      }
    }
    return userEmail;
  };

  const handleSuccessfulLogin = async () => {
    try {
      setIsLoading(true);
      const user = await getCurrentUser();
      const userEmail = await getUserEmail(user);
      if (!userEmail) {
        throw new Error("Unable to get user email");
      }

      await cacheService.invalidateAllCaches();
      updateAuthStatus(true, userEmail);
      navigate("/sketch");
    } catch (error) {
      console.error("Error during login:", error);
      setError("Failed to complete login process. Please try again.");
      updateAuthStatus(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await signIn({ username: email, password });
      await handleSuccessfulLogin();
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        setIsConfirming(true);
        await resendSignUpCode({ username: email });
        setError(
          "Account not confirmed. A new confirmation code has been sent to your email."
        );
      } else {
        setError("Failed to login: " + err.message);
      }
      updateAuthStatus(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await signUp({
        username: email,
        password,
        attributes: { name },
      });
      setIsConfirming(true);
      setError(
        "A confirmation code has been sent to your email. Please enter it below to confirm your account."
      );
    } catch (err) {
      setError(err.message || "An error occurred during sign up");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await confirmSignUp({ username: email, confirmationCode });
      setError("Account confirmed successfully. You can now login.");
      setIsConfirming(false);

      // If this was a sign up flow, sign in automatically
      if (isSignUp) {
        await signIn({ username: email, password });
        await handleSuccessfulLogin();
      }
    } catch (err) {
      setError("Error confirming account: " + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await resendSignUpCode({ username: email });
      setError("Confirmation code resent. Please check your email.");
    } catch (err) {
      setError("Error resending code: " + err.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setIsLoading(true);
      await cacheService.invalidateAllCaches();
      await signInWithRedirect({ provider: "Google" });
    } catch (error) {
      console.error("Google login error:", error);
      setError("Failed to initiate Google login. Please try again.");
      setIsLoading(false);
    }
  };

  const handleSendRecoveryCode = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsLoading(true);
    try {
      await resetPassword({ username: email });
      setSuccess("Verification code has been sent to your email.");
      setRecoveryStep(2);
    } catch (err) {
      setError(
        err.message || "An error occurred during password reset initiation."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (newPassword !== confirmRecoveryPassword) {
      setError("Passwords do not match.");
      return;
    }

    setIsLoading(true);
    try {
      await confirmResetPassword({
        username: email,
        confirmationCode: recoveryCode,
        newPassword,
      });
      setSuccess(
        "Your password has been successfully reset. You can now log in with your new password."
      );
      setTimeout(() => {
        setIsRecovering(false);
        setRecoveryStep(1);
        setNewPassword("");
        setConfirmRecoveryPassword("");
        setRecoveryCode("");
        setEmail("");
        setSuccess("");
      }, 3000);
    } catch (err) {
      setError(
        err.message || "An error occurred during password reset confirmation."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="desktop-sign-in">
      <div className="div">
        <div className="overlap">
          <div className="left-column">
            <div className="top" />
            <div className="content">
              {error && (
                <div
                  style={{
                    color: "red",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {error}
                </div>
              )}

              {success && (
                <div
                  style={{
                    color: "green",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  {success}
                </div>
              )}

              {/* Confirmation Code for Sign Up */}
              {isConfirming && !isRecovering && (
                <form className="form" onSubmit={handleConfirmSignUp}>
                  <div className="div-wrapper">
                    <input
                      type="text"
                      placeholder="Confirmation Code"
                      value={confirmationCode}
                      onChange={(e) => setConfirmationCode(e.target.value)}
                      required
                    />
                  </div>

                  <button className="button" type="submit" disabled={isLoading}>
                    <div className="text-wrapper-5">
                      {isLoading ? "Processing..." : "Confirm Account"}
                    </div>
                  </button>

                  <button
                    type="button"
                    className="button-2"
                    style={{ marginTop: "10px" }}
                    onClick={handleResendCode}
                    disabled={isLoading}
                  >
                    <div className="text-wrapper-7">Resend Code</div>
                  </button>
                </form>
              )}

              {/* Sign In / Sign Up Forms */}
              {!isConfirming && !isRecovering && (
                <form
                  className="form"
                  onSubmit={isSignUp ? handleSignUpSubmit : handleSignInSubmit}
                >
                  {isSignUp && (
                    <div className="input" style={{ marginTop: "0px" }}>
                      <input
                        type="text"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  )}

                  <div
                    className="input"
                    style={{ marginTop: isSignUp ? "20px" : "0px" }}
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="input">
                    <input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>

                  <button className="button" type="submit" disabled={isLoading}>
                    <div className="text-wrapper-5">
                      {isLoading
                        ? "Processing..."
                        : isSignUp
                        ? "Sign up"
                        : "Sign in"}
                    </div>
                  </button>

                  <div className="or">
                    <div className="vector" />
                    <div className="text-wrapper-6">or</div>
                    <div className="vector" />
                  </div>

                  <div className="button-2" onClick={handleGoogleLogin}>
                    <div className="text-wrapper-7">
                      {isLoading
                        ? "Processing..."
                        : isSignUp
                        ? "Sign up with Google"
                        : "Sign in with Google"}
                    </div>
                    <Plus
                      className="platform-google-shape-original-colored-true"
                      color="#8029FF"
                    />
                  </div>

                  {!isSignUp && (
                    <p className="need-an-account">
                      <span className="span">Need an account? </span>
                      <span
                        className="text-wrapper-8"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setError("");
                          setIsConfirming(false);
                          setIsSignUp(true);
                        }}
                      >
                        Create one
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#7f29ff",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setError("");
                          setSuccess("");
                          setIsRecovering(true);
                          setRecoveryStep(1);
                          setIsSignUp(false);
                          setIsConfirming(false);
                        }}
                      >
                        Forgot Password?
                      </span>
                    </p>
                  )}

                  {isSignUp && (
                    <p className="need-an-account">
                      <span className="span">Already have an account? </span>
                      <span
                        className="text-wrapper-8"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setError("");
                          setIsConfirming(false);
                          setIsSignUp(false);
                        }}
                      >
                        Sign in
                      </span>
                    </p>
                  )}
                </form>
              )}

              {/* Password Recovery Flow */}
              {isRecovering && (
                <form
                  className="form"
                  onSubmit={
                    recoveryStep === 1
                      ? handleSendRecoveryCode
                      : handleResetPasswordSubmit
                  }
                >
                  {recoveryStep === 1 && (
                    <div className="input">
                      <input
                        type="email"
                        placeholder="Your Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  )}

                  {recoveryStep === 2 && (
                    <>
                      <div className="input">
                        <input
                          type="text"
                          placeholder="Verification Code"
                          value={recoveryCode}
                          onChange={(e) => setRecoveryCode(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input">
                        <input
                          type="password"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          required
                        />
                      </div>

                      <div className="input">
                        <input
                          type="password"
                          placeholder="Confirm New Password"
                          value={confirmRecoveryPassword}
                          onChange={(e) =>
                            setConfirmRecoveryPassword(e.target.value)
                          }
                          required
                        />
                      </div>
                    </>
                  )}

                  <button className="button" type="submit" disabled={isLoading}>
                    <div className="text-wrapper-5">
                      {isLoading
                        ? "Processing..."
                        : recoveryStep === 1
                        ? "Send Reset Code"
                        : "Reset Password"}
                    </div>
                  </button>

                  <p
                    className="need-an-account"
                    style={{ marginTop: "10px", textAlign: "center" }}
                  >
                    <span
                      className="text-wrapper-8"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsRecovering(false);
                        setRecoveryStep(1);
                        setError("");
                        setSuccess("");
                      }}
                    >
                      Back to {isSignUp ? "Sign Up" : "Sign In"}
                    </span>
                  </p>
                </form>
              )}
            </div>
          </div>

          {/* Tabs */}
          {!isRecovering && !isConfirming && (
            <div className="tabs">
              <div
                onClick={() => {
                  setIsSignUp(false);
                  setError("");
                  setIsConfirming(false);
                }}
                style={{ cursor: "pointer" }}
                className={isSignUp ? "component-2-instance" : "component-2"}
              >
                <span className="component-instance">Sign In</span>
              </div>

              <div
                onClick={() => {
                  setIsSignUp(true);
                  setError("");
                  setIsConfirming(false);
                }}
                style={{ cursor: "pointer" }}
                className={isSignUp ? "component-2" : "component-2-instance"}
              >
                <span className="design-component-instance-node">Sign Up</span>
              </div>
            </div>
          )}

          <img
            className="arrow-left"
            alt="Arrow left"
            src="https://c.animaapp.com/cQyplKNA/img/arrow---left-2.svg"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />

          <div
            className="text-wrapper-9"
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            Back
          </div>
        </div>
      </div>

      <img src={RightSide} alt="" className={"right_side_image"} />
    </div>
  );
};

export default AuthPage;
