import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { Container } from "react-bootstrap";
import Sliders from "./Sliders";
import { AppContext } from "../../../AppContext";

const Hero = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleGetStarted = () => {
    scrollToTop();
    if (isAuthenticated) {
      navigate('/sketch');
    } else {
      navigate('/AuthPage');
    }
  };

  // Temporarily commented out until demo is ready
  /*
  const handleWatchDemo = () => {
    scrollToTop();
    // Add your demo video logic here
  };
  */

  return (
    <div className={styles._hero}>
      <Container>
        <div className={styles._hero_content}>
          <div className={styles._wrapper}>
            <h1 className={styles._hero_title}>
              Breaking Into Fashion Just Got AI–Simple
            </h1>

            <div className={styles._hero_buttons}>
              <button 
                className={styles._hero_left_button}
                onClick={handleGetStarted}
              >
                Get Started Now
              </button>
              {/* Temporarily commented out until demo is ready
              <button 
                className={styles._hero_right_button}
                onClick={handleWatchDemo}
              >
                Watch Live Demo
              </button>
              */}
            </div>
          </div>

          <Sliders />
        </div>
      </Container>
    </div>
  );
};

export default Hero;