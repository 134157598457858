// import "./styles/About.css";
import Hero from "./About/Hero/Hero";
import Join from "./About/Join/Join";
import Team from "./About/Team/Team";

export const About = () => {
  return (
    <>
      <Hero />
      <Team />
      <Join />
    </>
  );
};

export default About;
