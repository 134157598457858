import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Building, MapPin, Tags, Loader } from "lucide-react";
import { AppContext } from "../../AppContext";
import useAPIService from "../APIService";
import ManufacturerSearch from "./ManufacturerSearch";
import UnifiedMap from "./ManufactureMap";
import Pagination from "../Pagination";
import { cacheService } from "../../cacheService";
import "./ManuList.css";

const UnifiedList = () => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModeLoading, setIsModeLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [locationPart, setLocationPart] = useState("");
  const [typePart, setTypePart] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchMode, setSearchMode] = useState(() => {
    // Check location state first
    if (location.state?.previousMode) {
      return location.state.previousMode;
    }
    // Then check cache
    const cachedState = cacheService.getListPageState();
    if (cachedState?.searchMode) {
      return cachedState.searchMode;
    }
    // Default to manufacturers if no stored mode
    return "manufacturers";
  });

  const { isDark } = useContext(AppContext);
  const navigate = useNavigate();
  const apiService = useAPIService();
  const abortControllerRef = useRef(null);
  const fetchingRef = useRef(false);
  const prefetchTimeoutRef = useRef(null);

  const LoadingState = () => (
    <>
      <div className="featured-section">
        <div className="featured-grid">
          <div className="elements-2">
            <div className="text-wrapper-2">...</div>
            <div className="text-wrapper-3">
              {searchMode === "manufacturers" ? "Manufacturers" : "Fabrics"}
            </div>
            <p className="stats-description">
              {searchMode === "manufacturers"
                ? "Explore our wide variety of manufacturers to find the best fit"
                : "Browse our extensive collection of fabrics for your next project"}
            </p>
            <div className="link">
              <MapPin className="stats-icon" />
            </div>
          </div>
        </div>
      </div>

      <div style={{
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: '250px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '12px',
        zIndex: 10
      }}>
        <Loader className="spinner-animation" size={32} />
        <span style={{
          fontSize: '1rem',
          color: '#666'
        }}>Loading {searchMode === "manufacturers" ? "Manufacturers" : "Fabrics"}...</span>
      </div>
    </>
  );

  const prefetchAdjacentPages = useCallback(async () => {
    if (prefetchTimeoutRef.current) {
      clearTimeout(prefetchTimeoutRef.current);
    }

    prefetchTimeoutRef.current = setTimeout(async () => {
      const pagesToPrefetch = [];
      if (currentPage > 1) pagesToPrefetch.push(currentPage - 1);
      if (currentPage < totalPages) pagesToPrefetch.push(currentPage + 1);

      for (const page of pagesToPrefetch) {
        if (!cacheService.getListData(page, searchQuery)) {
          try {
            const response =
              searchMode === "manufacturers"
                ? await apiService.GetManufacturerList(page, 10, searchQuery)
                : await apiService.GetFabricList(page, 10, searchQuery);

            cacheService.setListData(page, searchQuery, {
              items:
                searchMode === "manufacturers"
                  ? response.manufacturers
                  : response.fabrics,
              totalPages: response.total_pages,
              totalCount: response.total_count,
            });
          } catch (err) {
            console.debug("Prefetch failed for page", page, err);
          }
        }
      }
    }, 300);
  }, [currentPage, totalPages, searchQuery, searchMode, apiService]);

  const fetchData = useCallback(async () => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    const cachedData = cacheService.getListData(currentPage, searchQuery);
    if (cachedData) {
      setItems(cachedData.items);
      setTotalPages(cachedData.totalPages);
      setTotalCount(cachedData.totalCount);
      setIsLoading(false);
      setIsModeLoading(false);
      fetchingRef.current = false;
      prefetchAdjacentPages();
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      let response;
      if (searchMode === "manufacturers") {
        response = await apiService.GetManufacturerList(
          currentPage,
          10,
          searchQuery,
          abortControllerRef.current.signal
        );
      } else {
        response = await apiService.GetFabricList(
          currentPage,
          10,
          searchQuery,
          abortControllerRef.current.signal
        );
      }

      const responseData = {
        items:
          searchMode === "manufacturers"
            ? response.manufacturers
            : response.fabrics,
        totalPages: response.total_pages,
        totalCount: response.total_count,
      };

      cacheService.setListData(currentPage, searchQuery, responseData);

      setItems(responseData.items);
      setTotalPages(response.total_pages);
      setTotalCount(response.total_count);

      cacheService.setListPageState({
        locationPart,
        typePart,
        searchMode,
        currentPage,
      });

      prefetchAdjacentPages();
    } catch (err) {
      if (err.name === "AbortError") return;
      console.error(`Error fetching ${searchMode}:`, err);
      setError(`Failed to fetch ${searchMode}. Please try again later.`);
    } finally {
      setIsLoading(false);
      setIsModeLoading(false);
      fetchingRef.current = false;
    }
  }, [
    currentPage,
    searchQuery,
    searchMode,
    apiService,
    prefetchAdjacentPages,
    locationPart,
    typePart,
  ]);

  useEffect(() => {
    fetchData();
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (prefetchTimeoutRef.current) {
        clearTimeout(prefetchTimeoutRef.current);
      }
    };
  }, [fetchData]);

  useEffect(() => {
    if (location.state) {
      if (location.state.previousSearch !== undefined) {
        const [prevLocation, prevType] =
          location.state.previousSearch.split(" ");
        setLocationPart(prevLocation || "");
        setTypePart(prevType || "");
        setSearchQuery(location.state.previousSearch);
      }
      if (location.state.previousPage !== undefined) {
        setCurrentPage(location.state.previousPage);
      }
      if (location.state.previousMode !== undefined) {
        setSearchMode(location.state.previousMode);
      }
      navigate(location.pathname, { replace: true });
    } else {
      const cachedState = cacheService.getListPageState();
      if (cachedState) {
        setLocationPart(cachedState.locationPart || "");
        setTypePart(cachedState.typePart || "");
        setSearchQuery(
          [cachedState.locationPart, cachedState.typePart]
            .filter(Boolean)
            .join(" ")
        );
        setCurrentPage(cachedState.currentPage || 1);
        setSearchMode(cachedState.searchMode || "manufacturers");
      }
    }
  }, [location, navigate]);

  const handleItemClick = useCallback(
    (item) => {
      const route = searchMode === "manufacturers" ? "manufacturer" : "fabric";
      navigate(`/${route}/${encodeURIComponent(item.name)}`, {
        state: {
          from: "list",
          previousPage: currentPage,
          previousSearch: searchQuery,
          previousMode: searchMode,
          itemData: item,
          locationPart,
          typePart,
          originalSource: location.state?.originalSource,
        },
      });
    },
    [
      navigate,
      currentPage,
      searchQuery,
      searchMode,
      locationPart,
      typePart,
      location.state,
    ]
  );

  const handleSearch = useCallback(
    ({ location, type, mode }) => {
      setLocationPart(location);
      setTypePart(type);

      const combinedQuery = [location, type].filter(Boolean).join(" ");
      
      // Only set loading state when switching modes
      if (mode !== searchMode) {
        setIsModeLoading(true);
        setItems([]); // Clear items when switching modes
      }

      if (combinedQuery === searchQuery && mode === searchMode) return;

      setSearchMode(mode);
      setSearchQuery(combinedQuery);
      setCurrentPage(1);

      cacheService.setListPageState({
        locationPart: location,
        typePart: type,
        searchMode: mode,
        currentPage: 1,
      });

      cacheService.invalidateListCache();
    },
    [searchQuery, searchMode]
  );


  const handlePageChange = useCallback(
    (pageNumber) => {
      if (pageNumber === currentPage) return;
      setCurrentPage(pageNumber);

      cacheService.setListPageState({
        locationPart,
        typePart,
        searchMode,
        currentPage: pageNumber,
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    [currentPage, locationPart, typePart, searchMode]
  );

  const renderFeaturedCard = useCallback(
    (item) => (
      <div
        className="overlap-group-wrapper"
        onClick={() => handleItemClick(item)}
      >
        <div className="overlap-group">
          {item.cover_image || item.base64_image ? (
            <img
              className="featured-image"
              src={`data:image/jpeg;base64,${
                item.cover_image || item.base64_image
              }`}
              alt={item.name}
            />
          ) : (
            <div
              className="featured-image"
              style={{ backgroundColor: "#f5f5f5" }}
            >
              <Building size={40} className="text-gray-400" />
            </div>
          )}

          <div className="overlay-gradient" />
          <div className="span-featured">
            <div className="featured">FEATURED</div>
          </div>

          <div className="heading-link-villa">{item.name}</div>

          {searchMode === "manufacturers" ? (
            <div className="featured-specialties">
              {(item.specialties || []).map((specialty, index) => (
                <span key={index} className="featured-specialty-tag">
                  {specialty}
                </span>
              ))}
            </div>
          ) : (
            <div className="featured-specialties">
              {item.composition && (
                <span className="featured-specialty-tag">
                  {item.composition}
                </span>
              )}
            </div>
          )}

          <div className="location-wrapper">
            <MapPin className="location-icon" size={18} />
            <div className="link-2">
              {searchMode === "manufacturers"
                ? item.location
                : item.supplier?.location || "Location not available"}
            </div>
          </div>
        </div>
      </div>
    ),
    [handleItemClick, searchMode]
  );

  const renderRegularCard = useCallback(
    ({ item }) => (
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="manufacturer-card"
        onClick={() => handleItemClick(item)}
      >
        <div className="card-container">
          <div className="image-container">
            {item.cover_image || item.base64_image ? (
              <img
                className="cover-image"
                src={`data:image/jpeg;base64,${
                  item.cover_image || item.base64_image
                }`}
                alt={item.name}
              />
            ) : (
              <div className="image-placeholder">
                <Building size={40} />
              </div>
            )}
          </div>

          <div className="card-content">
            <div className="company-info">
              <h3 className="company-name">{item.name}</h3>
              <p className="location">
                {searchMode === "manufacturers"
                  ? item.location || "Location not available"
                  : item.supplier?.location || "Location not available"}
              </p>
            </div>

            <hr className="divider" />

            <div className="specialties">
              {searchMode === "manufacturers" ? (
                (item.specialties || []).map((specialty, index) => (
                  <span key={index} className="specialty-tag">
                    {specialty}
                  </span>
                ))
              ) : (
                <>
                  {item.composition && (
                    <span className="specialty-tag">{item.composition}</span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    ),
    [handleItemClick, searchMode]
  );

  return (
    <>
      <div className="desktop-manufacturer">
        <div className="overlap">
          <div className="group" />
          <div className="big-card">
            <UnifiedMap
              items={items}
              mode={searchMode}
              onLocationSelect={(location) => {
                handleSearch({
                  location,
                  type: typePart,
                  mode: searchMode,
                });
              }}
            />
            <ManufacturerSearch
              isDark={false}
              onSearch={handleSearch}
              initialMode={searchMode}
              initialLocationQuery={locationPart}
              initialTypeQuery={typePart}
            />
          </div>
        </div>
      </div>

      <div className="content-layout">
  {isModeLoading ? (
    <LoadingState />
  ) : (
    <>
      <div className="featured-section">
        <div className="featured-grid">
          <div className="elements-2">
            <div className="text-wrapper-2">{totalCount}+</div>
            <div className="text-wrapper-3">
              {searchMode === "manufacturers" ? "Manufacturers" : "Fabrics"}
            </div>
            <p className="stats-description">
              {searchMode === "manufacturers"
                ? "Explore our wide variety of manufacturers to find the best fit"
                : "Browse our extensive collection of fabrics for your next project"}
            </p>
            <div className="link">
              <MapPin className="stats-icon" />
            </div>
          </div>

          {items.slice(0, 2).map((item) => (
            <React.Fragment key={item.id}>
              {renderFeaturedCard(item)}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="regular-grid">
        {items.slice(2).map((item) => (
          <React.Fragment key={item.id}>
            {renderRegularCard({ item })}
          </React.Fragment>
        ))}
      </div>

      {totalPages > 1 && (
        <div className="pagination-wrapper">
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isDark={false}
            className="justify-content-center"
          />
        </div>
      )}
    </>
  )}
</div>
    </>
  );
};

export default React.memo(UnifiedList);