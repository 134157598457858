import React, { useContext, useRef, useEffect } from "react";
import { useAnimation } from "framer-motion";
import { AppContext } from "../AppContext";
import "./styles/Home.css";
import Hero from "./Home/Hero/Hero";
import Mid from "./Home/Mid/Mid";
import Content from "./Home/Content/Content";

const MainPage = () => {
  const { isAuthenticated } = useContext(AppContext);

  // Controls for animation using IntersectionObserver
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start("visible");
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) observer.observe(ref.current);
    return () => {
      if (ref.current) observer.unobserve(ref.current);
    };
  }, [controls]);

  return (
    <div>
      <Hero />
      <Mid />
      <Content />
    </div>
  );
};

export default MainPage;
