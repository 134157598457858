import team1 from "../../../images/headshots/Aleksey.jpg";
import team2 from "../../../images/headshots/Ana.png";
import team3 from "../../../images/headshots/Harrison.jpg";
import team4 from "../../../images/headshots/Jidnyesh.jpeg";
import team5 from "../../../images/headshots/Max.jpg";
import team6 from "../../../images/headshots/Qiming.png";
import team7 from "../../../images/headshots/Vandana.png";

export const team = [
  {
    id: 1,
    name: "Harrison Muchnic",
    description:
      "As Pomu's founder, Harrison developed our revolutionary manufacturer matching system. His machine learning expertise enables the platform to analyze design specifications and intelligently pair designers with their ideal manufacturing partners.",
    image: team3,
  },
  {
    id: 2,
    name: "Max Ludwick",
    description:
      "Max turned Pomu from concept to reality by building our entire web infrastructure from the ground up. Drawing on years of software development, he created an intuitive platform that seamlessly connects fashion designers with manufacturers.",
    image: team5,
  },
  {
    id: 3,
    name: "Qiming Liu",
    description:
      "Qiming architected Pomu's manufacturer database and communication systems. His expertise in data engineering ensures our platform maintains accurate, up-to-date manufacturer profiles and capabilities.",
    image: team6,
  },
  {
    id: 4,
    name: "Aleksey Khvan",
    description:
      "Previously a brand manager for luxury fashion labels, Aleksey now leads Pomu's outreach to emerging designers and manufacturers. His insider knowledge of the fashion industry helps us understand designers' challenges.",
    image: team1,
  },
  {
    id: 5,
    name: "Jidnyesh Jawanjal",
    description:
      "Jidnyesh built and maintains Pomu's secure cloud infrastructure. His expertise in scalable architecture ensures our platform can efficiently serve designers worldwide while maintaining data protection.",
    image: team4,
  },
  {
    id: 6,
    name: "Anastasiia Eremina",
    description:
      "Anastasiia shapes Pomu's comprehensive visual identity and interface design. Her expertise in creating intuitive visual systems ensures our platform delivers a cohesive and engaging experience.",
    image: team2,
  },
  {
    id: 7,
    name: "Vandana Rajesh",
    description:
      "Vandana orchestrates Pomu's end-to-end user experience and product strategy. She transforms complex technical features into seamless workflows, ensuring both designers and manufacturers can effectively navigate our platform.",
    image: team7,
  },
];
