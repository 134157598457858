import React, { useState, useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import styles from "./styles.module.scss";

const Hero = () => {
  const [activeTab, setActiveTab] = useState("story");
  const [indicatorStyle, setIndicatorStyle] = useState({});
  const tabsRef = useRef({});

  useEffect(() => {
    // Update indicator position when active tab changes
    const activeTabElement = tabsRef.current[activeTab];
    if (activeTabElement) {
      setIndicatorStyle({
        left: `${activeTabElement.offsetLeft}px`,
        width: `${activeTabElement.offsetWidth}px`,
      });
    }
  }, [activeTab]);

  const tabContent = {
    story:
      "Pomu began when Harrison encountered a designer struggling to find reliable manufacturers. Recognizing the potential for innovation, he brought together our founding team of engineers and designers. Together, we built an AI-powered platform that bridges the gap between creative vision and manufacturing reality. Today, we continue to evolve this collaborative vision, making quality production accessible to designers everywhere.",
    goals:
      "Our mission is to democratize fashion manufacturing by creating seamless connections between designers and manufacturers. We aim to empower emerging designers with the tools and partnerships they need to bring their creative visions to life.",
    different:
      "Unlike traditional manufacturing networks, our AI-powered platform intelligently matches designers with manufacturers based on detailed specifications, capabilities, and past performance. This data-driven approach ensures optimal partnerships and successful productions.",
    offer:
      "We provide a comprehensive suite of tools including AI-powered manufacturer matching, detailed specification management, real-time communication channels, and production tracking - everything needed to turn designs into reality.",
  };

  return (
    <div className={styles._about_hero}>
      <Container>
        <div className={styles._about_wrapper}>
          <div className={styles._about_heading_wrapper}>
            <h2 className={styles._about_heading}>
              Our Strength Is Building Partnerships
            </h2>

            {/* <div>
              <button className={styles._about_button}>
                Explore our services{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M3.96875 11.9004L9.21875 6.65039L3.96875 1.40039"
                    stroke="white"
                    stroke-width="1.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div> */}
          </div>

          <div className={styles._about_tabs_section}>
            <div className={styles._about_tabs}>
              <div className={styles._frame2}>
                {["story", "goals", "different", "offer"].map((tab) => (
                  <div
                    key={tab}
                    ref={(el) => (tabsRef.current[tab] = el)}
                    className={`${styles._tab_item} ${
                      activeTab === tab ? styles._tab_item_active : ""
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab === "story" && "Our story"}
                    {tab === "goals" && (
                      <>
                        Our goals
                        <br />
                        and mission
                      </>
                    )}
                    {tab === "different" && (
                      <>
                        What makes us
                        <br />
                        different
                      </>
                    )}
                    {tab === "offer" && (
                      <>
                        What we can
                        <br />
                        offer
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={styles._bio_section}>
              <p className={styles._bio_text}>{tabContent[activeTab]}</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
