import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./styles/GlobalFooter.css";
import { AppContext } from '../AppContext';

const GlobalFooter = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppContext);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleGetStarted = () => {
    scrollToTop();
    if (isAuthenticated) {
      navigate('/sketch');
    } else {
      navigate('/AuthPage');
    }
  };

  // Custom link component that scrolls to top
  const ScrollToTopLink = ({ to, children, className }) => {
    const handleClick = (e) => {
      scrollToTop();
    };

    return (
      <Link to={to} className={className} onClick={handleClick}>
        {children}
      </Link>
    );
  };

  return (
    <div className="pomu-footer-container">
      <footer className="pomu-footer">
        <div className="footer-content">
          <div className="footer-heading">
            <p className="footer-title">
              <span className="title-line">
                Get back to design.
                <br />
              </span>
              <span className="title-subline">Let Pomu handle the rest.</span>
            </p>
          </div>

          <div className="footer-cta">
            <button onClick={handleGetStarted} className="footer-cta-link">
              Get Started Now
            </button>
          </div>

          <div className="footer-links-row">
            <div className="footer-copyright">© 2025 Pomu</div>
            <div className="footer-nav-links">
              <ScrollToTopLink to="/contactform" className="footer-link">
                Contact
              </ScrollToTopLink>
              <ScrollToTopLink to="/about" className="footer-link">
                About
              </ScrollToTopLink>
              <ScrollToTopLink to="/terms-of-service" className="footer-link">
                Terms of Use
              </ScrollToTopLink>
              <ScrollToTopLink to="/privacy-policy" className="footer-link">
                Privacy Policy
              </ScrollToTopLink>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default GlobalFooter;