import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAPIService from './APIService';
import './styles/Careers.css';
import { cacheService } from '../cacheService';
import { Flame, Brain, Rocket, Sparkles } from 'lucide-react';



const Hero = () => (
  <div className="careers-hero">
    <h1 className="careers-hero-title">Shape the Future of Fashion</h1>
    <p className="careers-hero-subtitle">
      Join us in revolutionizing how designers and manufacturers collaborate globally through AI-powered solutions
    </p>
  </div>
);

const CoreValues = () => (
  <section className="core-values-section">
    <div className="careers-container">
      <h2 className="section-title">Join Our Vision</h2>
      <p className="careers-subtitle">
        We're building something unprecedented – AI that understands fashion as deeply as the most experienced designer. 
        It takes a special kind of team to make this happen.
      </p>
      <div className="values-grid">
        <div className="value-item">
          <div className="value-icon">
            <Flame className="h-8 w-8 text-purple-600" />
          </div>
          <h3 className="value-title">Fearless Innovation</h3>
          <p className="value-description">
            We're not just using AI – we're reinventing how it works for fashion. Bring your wildest ideas and help us push the boundaries of what's possible.
          </p>
        </div>
        <div className="value-item">
          <div className="value-icon">
            <Brain className="h-8 w-8 text-purple-600" />
          </div>
          <h3 className="value-title">Teach Us Something New</h3>
          <p className="value-description">
            Whether you're a fashion expert, tech wizard, or bring a completely different perspective – we want to learn from you. Your unique viewpoint will shape our future.
          </p>
        </div>
        <div className="value-item">
          <div className="value-icon">
            <Rocket className="h-8 w-8 text-purple-600" />
          </div>
          <h3 className="value-title">Build & Own It</h3>
          <p className="value-description">
            This isn't about following playbooks – it's about writing them. You'll have the freedom to identify opportunities and the resources to pursue them.
          </p>
        </div>
        <div className="value-item">
          <div className="value-icon">
            <Sparkles className="h-8 w-8 text-purple-600" />
          </div>
          <h3 className="value-title">Impact Over Everything</h3>
          <p className="value-description">
            We're transforming how a global industry works. Every feature you build, every solution you design will impact thousands of fashion creators worldwide.
          </p>
        </div>
      </div>
    </div>
  </section>
);


const PositionCard = ({ id, title, department, description, onClick }) => (
  <div className="position-card" onClick={() => onClick(id)}>
    <h3>{title}</h3>
    <div className="position-meta">
      <span className="position-tag">{department}</span>
    </div>
  </div>
);

const OpenPositions = () => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const api = useAPIService();

  useEffect(() => {
    const fetchPositions = async () => {
      try {
        // Try to get cached positions first
        const cachedPositions = cacheService.getPositions();
        if (cachedPositions) {
          setPositions(cachedPositions);
          setLoading(false);
          return;
        }

        // If no cache, fetch from API
        const response = await api.GetPositions();
        const positionsData = response.positions || [];
        setPositions(positionsData);
        
        // Cache the positions
        cacheService.setPositions(positionsData);
      } catch (err) {
        console.error('Error fetching positions:', err);
        setPositions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPositions();
  }, [api]);


  const handlePositionClick = (positionId) => {
    navigate(`/careers/apply/${positionId}`);
  };

  if (loading) {
    return (
      <section className="careers-section careers-positions">
        <div className="careers-container">
          <h2 className="section-title">Open Positions</h2>
          <div className="loading">Loading...</div>
        </div>
      </section>
    );
  }

  return (
    <section className="careers-section careers-positions">
      <div className="careers-container">
        <h2 className="section-title">Open Positions</h2>
        {positions.length > 0 ? (
          <div className="positions-grid">
            {positions.map((position) => (
              <PositionCard
                key={position.id}
                {...position}
                onClick={handlePositionClick}
              />
            ))}
          </div>
        ) : (
          <div className="positions-message">
            <p>We don't have any open positions at the moment.</p>
            <p>Check out our general application below if you'd like to join our team!</p>
          </div>
        )}
      </div>
    </section>
  );
};

const Wildcard = () => {
  const navigate = useNavigate();

  const handleGeneralApplication = () => {
    navigate('/careers/apply/general');
  };

  return (
    <section className="careers-section wildcard-section">
      <div className="careers-container">
        <h2 className="section-title">Don't see your perfect role?</h2>
        <p className="careers-subtitle">
          We're always looking for talented individuals who are passionate
        </p>
        <button className="careers-cta" onClick={handleGeneralApplication}>
          Submit General Application
        </button>
      </div>
    </section>
  );
};

const Careers = () => {
  return (
    <div className="careers-page">
      <Hero />
      <CoreValues />
      <OpenPositions />
      <Wildcard />
    </div>
  );
};

export default Careers;